﻿/// <reference path="../../../../node_modules/bootstrap/scss/bootstrap.scss" />
/// <reference path="../_variables.scss" />
/// <reference path="../icons/_variables.scss" />
 
// Main Banner 
$color-red-bulls: #c50316;
$color-dark-blue-bg: #15135d;
$strip-bg-color: #202446;
$star-bg: url("../Images/pluses/star.svg");
.main-banner { position:relative; display:flex; flex-direction:column; max-height:60rem; 
               height:calc(var(--viewh, 100vh) - var(--rwh, 52px) - 62px); // height = viewport - risk-warning - header
    background-color:$dark-blue; box-shadow:$card-shadow; overflow:hidden; transition:height .3s ease; 
    @include media-breakpoint-up(md){ height:auto; }
    &:before, &:after { margin-bottom:-4.5rem; flex:1 1 100%; z-index:0; animation:fadeIn .3s; background-repeat:no-repeat;
		@include media-breakpoint-up(md){ position:absolute; top:0; #{"/*! rtl:ignore */"}right:0; width:100%; height:100%; margin-bottom:0; }
    }
    .plus-bg { z-index:0;
		&-small { width:6rem; height:6rem; top:-20%; #{"/*! rtl:ignore */"}left:auto; #{"/*! rtl:ignore */"}right:-2%; opacity:.5;
			@include media-breakpoint-up(md){ top:80%; opacity:1; }
            @include media-breakpoint-up(xl){ top:20%; #{"/*! rtl:ignore */"}left:-14%; #{"/*! rtl:ignore */"}right:auto; }		}
		&-medium { width:10rem; height:10rem; top:-100%; #{"/*! rtl:ignore */"}left:-4%; opacity:.5;
			@include media-breakpoint-up(lg){ top:-10%; #{"/*! rtl:ignore */"}left:0; opacity:1; }
		}
		&-large { 
			@include media-breakpoint-up(xxl){ width:28rem; height:28rem; top:50%; #{"/*! rtl:ignore */"}left:-10%; }
		}
	} 
    .main-banner-wrap { #{"/*! rtl:ignore */"}direction: ltr;}
   
    #{$rtl-languages} {
        .hero-content, .marketing-content { #{"/*! rtl:ignore */"}direction: rtl; }
    }
    &.hero-banner, &.marketing-banner {
        .plus-bg {
            &-small { #{"/*! rtl:ignore */"}left:auto; #{"/*! rtl:ignore */"}right:-2%;
                @include media-breakpoint-up(xl){ #{"/*! rtl:ignore */"}left:-14%; #{"/*! rtl:ignore */"}right:auto; }
            }
            &-medium { #{"/*! rtl:ignore */"}left:-4%; #{"/*! rtl:ignore */"}right:auto;
                @include media-breakpoint-up(lg){ #{"/*! rtl:ignore */"}left:0; }
            }
            &-large { #{"/*! rtl:ignore */"}right:auto;
                @include media-breakpoint-up(xxl){ #{"/*! rtl:ignore */"}left:-10%; }
            }
	    }
    }
    &.hero-banner {  
        --banner-bg-top-position: 0;
        .home & { --banner-bg-top-position: -3rem; }
        // NOTE: All background size claculations here are based on this specific image and the subject it contains, it does not transfer to other images
        &:before{ width:100%; right:0; background-size:cover;  #{"/*! rtl:ignore */"}background-position:60% 40%; background-image:url("../Images/newhome/img/hero-slider-cfd-mobile.webp");
                 #{$no-webp} { background-image:url("../Images/newhome/img/hero-slider-cfd-mobile.png"); }
                 @include media-breakpoint-up(md){ background-image:url("../Images/newhome/img/hero-slider-cfd.webp");  #{"/*! rtl:ignore */"}background-position:right -50vw top 0; background-size:auto 100%; 
                    #{$no-webp} { background-image:url("../Images/newhome/img/hero-slider-cfd.png"); }
                  }
                @include media-breakpoint-up(lg){ #{"/*! rtl:ignore */"}background-position:right -40vw top var(--banner-bg-top-position); }
                @include media-breakpoint-up(xl){ #{"/*! rtl:ignore */"}background-position:right -20vw top var(--banner-bg-top-position); }
                @include media-breakpoint-up(xxl){ #{"/*! rtl:ignore */"}background-position:right -15vw top var(--banner-bg-top-position); }
                @include media-breakpoint-up(xxxl){ #{"/*! rtl:ignore */"}background-position:right -8vw top var(--banner-bg-top-position); background-size:contain; }
        }
    }

    &.marketing-banner {
        &:before { width:100%; right:0; margin-bottom:-50%; background-size:cover;  #{"/*! rtl:ignore */"}background-position:35% 0; background-image:url("../Images/newhome/img/us-hero-bulls-banner-mobile.webp");  
            #{$no-webp} { background-image: url("../Images/newhome/img/us-hero-bulls-banner-mobile.png"); }
            @include media-breakpoint-up(md){ margin-bottom:0; background-image:url("../Images/newhome/img/us-hero-bulls-banner.webp"); background-size:65% auto;  #{"/*! rtl:ignore */"}background-position:135% 100%; 
                 #{$no-webp} { background-image:url("../Images/newhome/img/us-hero-bulls-banner.png"); }
            }
            @include media-breakpoint-up(lg){ background-size:auto 130%;  #{"/*! rtl:ignore */"}background-position:right -15rem top -6.5rem; } 
        }
        &:after { content:''; position:absolute; #{$align-def}:0; top:0; z-index:0; width:100%; height:100%; background:linear-gradient(to bottom,rgba(140,153,195,0) 0,rgba(12,39,128,0.45) 35%,rgba(12,39,128,1) 60%); 
            @include media-breakpoint-up(md){ display:none; }
        }
        .banner-link-button { background-color:$color-red-bulls; 
                    &:after { content:$icon-chevron-up; }
        }
        &.extended-hours {
            &:before { background-size: auto 90%; background-image: url("../Images/newhome/img/extended_hours_banner_mobile.webp"); #{"/*! rtl:ignore */"}background-position: left 50% top 0;
                #{$no-webp} { background-image: url("../Images/newhome/img/extended_hours_banner_mobile.png"); }
                @include media-breakpoint-up(md) {background-image: url("../Images/newhome/img/extended_hours_banner_desktop.webp"); #{"/*! rtl:ignore */"}background-position: left -8rem top 1rem;
                    #{$no-webp} { background-image: url("../Images/newhome/img/extended_hours_banner_desktop.png"); }
                }
                @include media-breakpoint-up(xxxl) { #{"/*! rtl:ignore */"}background-position: left 6rem top 1rem; }
            }
            .main-banner-wrap { justify-content:flex-end; }
            .extended-hours-strip .banner-link-button{ background-color:$dark-blue; color:$white; }
        }
        &.elections-banner { 
            &:before { width:100%; margin-bottom:$spacer * -4.5; #{"/*! rtl:ignore */"}left:0; #{"/*! rtl:ignore */"}right:auto; background-size:cover; background-image:url("../Images/hero_banners/hero-elections-mobile.webp"); #{"/*! rtl:ignore */"}background-position:center top;
                #{$no-webp} { background-image: url("../Images/hero_banners/hero-elections-mobile.jpg"); }
                @include media-breakpoint-up(md){ width:50%; background-image:url("../Images/hero_banners/hero-elections.webp"); #{"/*! rtl:ignore */"}background-position:right center;
                    #{$no-webp} { background-image: url("../Images/hero_banners/hero-elections.jpg"); }
                }
                @include media-breakpoint-up(xxxl){ width:40%; }
            }
            &:after { display:none; height:120%; width:105%; top:-10%; #{"/*! rtl:ignore */"}transform:rotate(-8deg); background-image:$star-bg, $star-bg, $star-bg; #{"/*! rtl:ignore */"}background-position:right 2% top 15rem, right 40% bottom -6rem, right 60% top -8rem; background-size:30rem; background-repeat:no-repeat;
                @include media-breakpoint-up(xxxl){ display:block; }
            }
            .plus-bg { display:none; }
            .main-banner-wrap { justify-content:flex-end; }
        }        
    }
    &.extended-hours, &.elections-banner {
        .extended-hours, .elections-banner {
            &-content { padding:2rem 1rem; font-family:$font-family-title; font-size:1rem; text-align:center; 
                @include media-breakpoint-up(md){ font-size:1.125rem; margin:($spacer * 1.5) 0; text-align:start; }
                .marketing-banner-title strong { display:block; font-size:1.5rem; color:$light-blue; text-transform:uppercase; line-height:1;
                    @include media-breakpoint-up(md){ font-size:2rem; }
                    @include media-breakpoint-up(lg){ font-size:2.5rem; }
                    @include media-breakpoint-up(xl){ font-size:3rem; }
                    @include media-breakpoint-up(xxl){ font-size:3.5rem; }
                    @include media-breakpoint-up(xxxl){ font-size:4.5rem; }
                }
            }
        }        
        .marketing-banner-buttons-box { text-align:center;  
            @include media-breakpoint-up(md){ text-align:start; }
        }
    }
    
    &-wrap { padding:0; display:flex; flex-direction:column; justify-content:flex-end; position:relative; z-index:1; max-width:83.75rem; //width:100%;
		@include media-breakpoint-up(md){ min-height:34rem; flex-direction:row; justify-content:flex-start; align-items:center; }
		@include media-breakpoint-up(xl){ min-height:38rem; }
        @include media-breakpoint-up(xxl){ min-height:42rem; }
    }
    &-content { position:relative; z-index:0; color:$white; text-align:center; padding:2rem 1rem;
        &.marketing-content { display:flex; flex-direction:column; padding:1rem; bottom:0; z-index:1; 
            @include media-breakpoint-up(md){ bottom:auto; }
        }
        @include media-breakpoint-up(md){ display:flex; flex-direction:column; text-align:#{$align-def}; }
        @include media-breakpoint-up(lg){ text-align:center; }
        .blue-card-bg { transform: skew(0deg, rtlFlip(-2deg, 2deg)); min-height:26rem; height:100%;
            @include media-breakpoint-up(md){ display:none; }
        }
    }
    &-title, &-slogan { display:flex; justify-content:center; flex-direction:column; font-size:1.5rem; text-transform:uppercase; color:$white; font-weight:$font-weight-light; line-height:$line-height-small; margin-bottom:0;
        @include media-breakpoint-up(md){ font-size:3rem; line-height:1; }
        @include media-breakpoint-up(lg){ font-size: 4rem; }   
        @include media-breakpoint-up(xl){ font-size:5.5rem; } 
        strong { color:$light-blue; font-weight:$font-weight-bold; 
            span { color:$light-blue; }
        }
    }
    &-slogan { color:$light-blue; font-weight:$font-weight-bold; }
    &-desc { color:$white; font-size:1.25rem; font-weight:$font-weight-light; line-height:$line-height-small;  
        @include media-breakpoint-up(md){ font-size:1.5rem; margin-top:1.25rem; }  
        strong { display:block; margin-bottom:.5rem }
    }
    &-text { color:$white; font-size:1rem; line-height:1.1;
        @include media-breakpoint-up(md){ font-size:1.375rem; line-height:$line-height-small; padding-#{$align-opp}:20%; }
    } 
    &-anim { display:flex; align-items:center; justify-content:center; position:relative; font-size:1.25rem; height:1.5rem; color:$white; font-weight:$font-weight-light; 
        @include media-breakpoint-up(md){ font-size:2.25rem; margin-top:1.25rem; height:2rem; }  
        &-title { font-weight:$font-weight-light!important; }
        &-item { position: absolute; #{$align-opp}:0; #{$align-def}:0; font-weight:$font-weight-medium; line-height:1; opacity:0; white-space:nowrap; }
    }
    &-no-ad { font-size:1rem; padding:0 0 ($spacer * .25); font-weight:$font-weight-light; 
        @include media-breakpoint-up(md){ font-size:1.5rem; padding:($spacer * .25) 0; }  
        @include media-breakpoint-up(lg){ font-size:2rem; }
    }
    &-buttons { display:flex; flex-direction:row; justify-content:center; flex-wrap:wrap; width:100%;
        .button { margin:.3125rem; height:2.75rem; font-size:1rem; flex:1 1 40%; max-width:20rem;
            @media (orientation: portrait) { flex:1 1 100%; }
            &.hollow { border-color:transparent; } 
        }
        @include media-breakpoint-up(md){ max-width:20rem;
            .button { height:3.25rem; margin-#{$align-def}:0; flex:1 1 auto; 
                &:last-child { margin-#{$align-opp}:.3125rem; }
            }
        }
        @include media-breakpoint-up(lg){ max-width:100%; margin-top:2rem; 
            .button{ height:4rem; font-size:1.25rem; margin-#{$align-def}:.3125rem; 
                @media (orientation: portrait) { flex:1 1 40%; }
            }
        }
        .cta-wrap { display:flex; flex-wrap:wrap; justify-content:center; }
    }
    &-image { 
        img { object-fit:cover; width:100%; height:100%; }
        @include media-breakpoint-only(sm) { flex:0 1 50%; width:100%; position:relative; order:0;
            img { position:absolute; bottom:-5rem; height:auto; z-index:-1; margin:0 auto; left:0; right:0; }
        }
    } 
 
    .marketing-banner {
        &-logo { max-width:9.125rem; margin:0 auto .75rem; 
            @include media-breakpoint-up(md){ max-width:13.75rem; margin-bottom:1.5rem; margin-left:0; }
            @include media-breakpoint-up(lg){ max-width:18.25rem; margin-left:auto; }
        }
        &-title { font-size:1.375rem; color:$white; font-weight:$font-weight-light; 
          @include media-breakpoint-up(md){ font-size:1.75rem; }
          @include media-breakpoint-up(lg){ font-size:2.5rem; }
        } 
        &-bulls-name { margin:0 auto 1rem; width:96%; max-width:20rem;
            @include media-breakpoint-up(md){ width:100%; max-width:100%; margin-bottom:1.875rem }
        }
        &-link-more { display:flex; justify-content:center; margin-top:1rem; color:$white; font-size:1.125rem;
            @include media-breakpoint-up(md){ margin-top:2rem; font-size:1.5rem; justify-content:flex-start; }
            @include media-breakpoint-up(lg){ justify-content:center; }
            span { text-decoration:underline; 
                &:hover, &:focus { text-decoration:none; }
            }
            i::after { content:$icon-chevron-right; font-size:.75rem; padding:0 .5em; 
                @at-root [dir=rtl] #{&} { content:$icon-chevron-left; } // replacement for rtlFlip mixin
            }
        }
        
        // CN ZH 
        .lang-cn &, .lang-zh &, .lang-hu &, .lang-lt & {
            &-data { display:flex; flex-direction:column; }
            &-title { order:3; 
                @include media-breakpoint-only(sm) { margin-bottom:0; }
            }
            &-bulls-name { margin-top:1rem; margin-bottom:.5rem; }
        }
    }
    .banner-link { position:relative; bottom:0; width:100%; z-index:1; color:$white; text-align:center; font-size:1rem; background-color:$black; 
      @include media-breakpoint-up(md){ font-size:1.25rem; }
       &.extended-hours-strip {
            .banner-link-button { background-color:$light-blue; color:$dark-blue; font-size:.825rem; font-family:$font-family-title; flex-direction:column;
                @include media-breakpoint-up(lg){ flex-direction:row; }
                @include media-breakpoint-up(md) { font-size:1.25rem; }
                .hero-marketing-strip-slogan { padding:0 ($spacer * .5); line-height:$line-height-tight;
                    @include media-breakpoint-up(md) { padding:$spacer ($spacer * .5); }
                    }
                }
            }
      &.elections-banner-strip { padding:($spacer * .75) 6%; min-height:4rem; height:auto; display:flex; justify-content:center; align-items:center; color:$white; text-align:center; font-size:.75rem; font-family:$font-family-title; background-color:$strip-bg-color; background-image:url("../images/hero_banners/strip-main-bg.webp"), none; #{"/*! rtl:ignore */"}background-position:right -13rem center; background-size:auto 4rem; background-repeat:no-repeat;
            @include media-breakpoint-up(md){ padding-right:14%; padding-left:14%; #{"/*! rtl:ignore */"}background-position:right -12rem center, left 0 center; background-size:auto 100%; }
            @include media-breakpoint-up(lg){ padding-right:10%; padding-left:10%;  #{"/*! rtl:ignore */"}background-position:right -9rem center, left 0 center; }
            @include media-breakpoint-up(xl){ background-image:url("../images/hero_banners/strip-main-bg.webp"), url("../images/hero_banners/strip-side-bg.webp"); }
            @include media-breakpoint-up(xxxl){ #{"/*! rtl:ignore */"}background-position:right center, left center; }
            a { color:$white; text-decoration:none; }
            .banner-link-button{ display:flex; padding:0; background-color:transparent; color:$white; box-shadow:none;
                @include media-breakpoint-down(lg){ flex-direction:column; }
            }
            .hero-marketing-strip-slogan { font-size:.75rem; padding:0 ($spacer * .5); line-height:$line-height-tight;
                @include media-breakpoint-up(md){ font-size:1rem; }
                @include media-breakpoint-up(xl){ font-size:1.25rem; }
            }
        }
      &-button { background:transparent; width:100%; color:$white; display:flex; justify-content:center; align-items:center; padding:.75rem 0 0; border-radius:0; letter-spacing:$letter-spacing; cursor:pointer; transition:0.3s ease;
          @include media-breakpoint-up(md) { padding:.5rem 0; }
          @include media-breakpoint-up(lg) { flex-direction:row; padding-bottom:.75rem; }
          &:after { content:$icon-chevron-down; font-family:$icomoon-font-family; width:1.25rem; height:0.875rem; font-size:0.875rem; line-height:1; }
      }
    }
    &-bulls { 
        &-link-button { flex-direction: column; padding-bottom:0;
            &:after { order:1; }
        }
        &-wrap { display:flex; align-items:center; margin-#{$align-opp}:.5rem; order:0;
            @include media-breakpoint-up(lg){ order:1 }
        } 
        &-slogan { font-size:.625rem; order:1; margin-top:.375rem;
            @include media-breakpoint-up(md){ font-size:1.5rem; margin-top:0; } 
            @include media-breakpoint-up(xl){ font-size:1.75rem; } 
        }
        &-name { height:1.5rem; width:auto; 
            @include media-breakpoint-up(md){ height:2.25rem; }
        }
        &-logo { width:1.875rem; height:auto; margin:0 .625rem; 
            @include media-breakpoint-up(md){ width:3.25rem; margin:0 1rem; }
            &:last-child { @include media-breakpoint-up(lg){ display: none; } }
        }
    }   
    
    .button-rw-invest { 
        @include media-breakpoint-only(sm){ text-align: center; width: 100%; }
    }
    .rw-btn-invest { text-align:center; color:$white; margin-top:.5rem; margin-bottom:.5rem; font-size:.875rem; }
    .close { cursor: pointer; z-index: 2; position: absolute; #{$align-opp}:1rem; top: 1.75rem; width: 1.5rem; height: 1.5rem; opacity: .75;
      @include media-breakpoint-up(md) { width: 2rem; height: 2rem; #{$align-opp}:2rem; }
      &:hover, &:focus { opacity: 1; }
      &:before, &:after {
        position: absolute; top:0; #{$align-def}: .75rem; content: ''; height: 1.5rem; width: 2px; background-color: $white;
        @include media-breakpoint-up(md) { height: 2rem; #{$align-def}: 1rem; }
      }
      &:before { transform: rotate(45deg); }
      &:after { transform: rotate(-45deg); } 
    }
    
    &.hero-banner {
        &:before{ content:''; }
        .hero-content { display:block; animation:fadeIn .3s; }
        .marketing-content { display:none; }
        .close { display:none; } 
    }
    &.marketing-banner {
        &:before { content:''; }
        .marketing-content { display:flex; animation:fadeIn .3s; }
        .hero-content { display:none; }
        .sg-awareness { display:none; }
    }
    
    
    &.inner-banner {
        &:before { animation:none; width:100%; #{$align-opp}:0; background-size:cover; background-position:60% 0; background-image:$banner-image-gradient-small-device; content:'';
			@include media-breakpoint-up(md){ width:60%; background-position:50% 0; background-image:$banner-image-gradient; }
		}
        #{$rtl-languages} {
            .main-banner-wrap { direction:$dir-def; }
        }
        .main-banner-wrap { justify-content:center; }
        .main-banner-content { text-align:center; }
        .main-banner-title {
			@include media-breakpoint-up(lg){ font-size: 2.5rem; }   
			strong { @include media-breakpoint-up(xl){ font-size: 5.5rem; } }
		}
	  	.main-banner-desc { font-size: 1.125rem;
		  	@include media-breakpoint-up(md) { font-size:1.5rem; }
		  	.content{ color:$white; line-height:$line-height-small; }
        }
        &.small-height { margin-block-end:$spacer * 2.5;
            .plus-bg { z-index:0;
                &-medium { display:none; width:5rem; height:5rem; top:3rem; left:4%;
                    @include media-breakpoint-up(xxl){ display:block; }
                }
                &-large { display:none; width:10rem; height:10rem; top:auto; bottom:-5.5rem; left:4%;
                    @include media-breakpoint-up(md){ display:block; }
                    @include media-breakpoint-up(xxl){ left:15%; }
                }
            }
            .main-banner {
                &-wrap { min-height:auto;
                    @include media-breakpoint-up(md){ min-height:15rem; }
                }
                &-title { font-size:1.5rem;
                    @include media-breakpoint-up(md){ font-size:2.5rem; }
                    strong { font-size:2.5rem;
                        @include media-breakpoint-up(md){ font-size:3.875rem; }
                    }
                }
            }
        }
    }
    
    $marketing-strip-bg-url: url("https://cdn.plus500.com/Media/website-marketing-banner/crypto-bg.webp");
    .marketing-strip { min-height:4.375rem; text-align:center; display:flex; justify-content:center; align-items:center; align-content:center; flex-wrap:wrap; background-color:$dark-blue; border-top:.375rem solid scale-color($color-gold, $lightness:30%); z-index:1;
        &-crypto { background-image:$marketing-strip-bg-url; #{"/*! rtl:ignore */"}background-position:right -27rem center; background-size:auto 100%; background-repeat:no-repeat;
            @include media-breakpoint-up(md){#{"/*! rtl:ignore */"}background-position:right -25rem center; }
            @include media-breakpoint-up(lg){ background-image:url("https://cdn.plus500.com/Media/website-marketing-banner/crypto-bg-side.webp"), $marketing-strip-bg-url; #{"/*! rtl:ignore */"}background-position:left 0 center, right -25rem center; }
            @include media-breakpoint-up(xxl){ #{"/*! rtl:ignore */"}background-position:left 0 center, right -20rem center; }
            @include media-breakpoint-up(xxxl){ #{"/*! rtl:ignore */"}background-position:left 0 center, right -15rem center; }
        } 
        &-slogan { padding:0 5%; font-size:1rem; font-family:$font-family-title; color:$white;
            @include media-breakpoint-up(md){ font-size:1.5rem; }
            @include media-breakpoint-up(xl){ font-size:1.875rem; }
            a { display:block; color:$white; }
            span { color:$light-blue; font-weight:$font-weight-extra-bold; }
        }
    }
    
    @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
} 
.bonus-box { display:flex; align-items:center; justify-content:center;
        @include media-breakpoint-up(md){ justify-content:flex-start;  }
        @include media-breakpoint-up(lg){ justify-content:center; }  
        .link-bonus { display:flex; align-items:center; justify-content:center; flex:1 1 100%; width:100%; max-width:20rem; height:3.25rem; padding:.5rem 1rem; margin:.3125rem .3125rem 0; background-color:$dark-blue; color:$white; font-size:1.125rem; line-height:1.1; text-decoration:none; border:1px solid $white; border-radius:calc($border-radius-medium * 0.5); transition:all .3s linear;
            &:hover, &:focus { background-color:$cfd-blue; }
            [class^="icon-"] { flex:0 0 1.5rem; margin-#{$align-opp}:.75rem; font-size:120%;
                img, svg { width:100%; height:auto; }  
            }
            span { line-height:1; } 
            @include media-breakpoint-up(md){ margin-#{$align-def}:0; }
            @include media-breakpoint-up(lg){ height:4rem; max-width:40.625rem; font-size:1.25rem; margin:1rem .3125rem 0; }
        }
}

.hero {
    &-slider { max-height:42rem; overflow:hidden; opacity:0; transition:opacity .2s ease-out;
        &.slick-initialized { width:100%; opacity:1; max-height:none; 
            & + .hero-slider-navigation { opacity:1; visibility:visible; }
            @include media-breakpoint-down(md){ height:100%;
                &.slick-slider, .slick-list, .slick-track { height:100%; }
                .hero {
                    &-slide { display:flex; flex-direction: column; }
                    &-content {padding-top:$spacer * 1.5; padding-bottom:$spacer * 2.5; }
                }
             }
        }
        &-section { 
            .plus-bg-small { transform:rotate(-12deg) !important; }
            &.main-banner.hero-banner { 
                &:before { display:none; }
            } 
            &.hero-banner {
                .marketing-banner-container { display:none; }
            }
            &.marketing-banner {
                .hero-slider-wrap { display:none; }
            }
        }
        &-wrap { position:relative; 
            @include media-breakpoint-down(md){ flex:1 1 100%; }
        }
        &-navigation { position:absolute; bottom:.375rem; left:0; right:0; margin:0 auto; height:2.25rem; width:9rem; visibility:hidden; opacity:0; transition:opacity .2s ease-out;
			@include media-breakpoint-up(md){ bottom:1rem; }
			@include media-breakpoint-up(lg){ bottom:1.5rem; }
			button { background:none; border:none; }
			.slick-arrow { display:flex; align-items:center; color:$white;
				&.slick-prev, &.slick-next { width:2rem;
					&:before { font-size:1rem; color:$white; width:2rem; }
				}
				&.slick-prev { left:0; }
				&.slick-next { right:0; }
			}
			.slick-dots { display:flex; justify-content:center; align-items:center; height:2.25rem; width:auto; left:2rem; right:2rem;
				li {
					button { background:transparent; border:0 none; border-radius:50%; padding:0; width:1.25rem; height:1.25rem;
						&:before { border-radius:50%; opacity:1; z-index:1; background-color:$white; width:.75rem; height:.75rem; margin:$spacer * .25; }
						svg { opacity:0; width:100%; height:100%; fill:none; stroke:$light-blue; stroke-width:.25rem; stroke-dasharray:0 100; transform:rotate(-90deg); }
					}
					&.slick-active {
						button { 
							&:before { background-color:$light-blue; }
							svg { opacity:1; animation-name:slick-dots-anim; animation-iteration-count:infinite; animation-fill-mode:forwards; animation-timing-function:linear; }
						}
					}
			 	}
			}
		}
    }
    &-slide {
        &.no-ad {
            &:before { margin-bottom:-4.5rem; flex:1 1 100%; z-index:0; background-repeat:no-repeat; background-size:cover; content:"";
                 @include media-breakpoint-up(md){ position:absolute; top:0; left:0; width:100%; height:100%; margin-bottom:0; #{"/*! rtl:ignore */"}background-position:right -45vw top 0; background-size:auto 100%; }
                // NOTE: All background size claculations here are based on this specific image and the subject it contains, it does not transfer to other images
                @include media-breakpoint-up(lg){ #{"/*! rtl:ignore */"}background-position:right -25vw top 0; }
                @include media-breakpoint-up(xl){ #{"/*! rtl:ignore */"}background-position:right -20vw top 0; }
                @include media-breakpoint-up(xxl){ #{"/*! rtl:ignore */"}background-position:right -15vw top 0; }
                @include media-breakpoint-up(xxxl){ #{"/*! rtl:ignore */"}background-position:right -2vw top 0; background-size:contain; }
            }
        }
        &.invest-slide {
            &:before { background-image:url("../Images/newhome/img/hero-slider-invest-mobile.webp");
                 #{$no-webp} { background-image:url("../Images/newhome/img/hero-slider-invest-mobile.png"); }
                  @include media-breakpoint-up(md){ background-image:url("../Images/newhome/img/hero-slider-invest.webp"); 
                    #{$no-webp} { background-image:url("../Images/newhome/img/hero-slider-invest.png"); }
                  }
            }
        }
        &.cfd-slide {
            &:before { background-image:url("../Images/newhome/img/hero-slider-cfd-mobile.webp");
                 #{$no-webp} { background-image:url("../Images/newhome/img/hero-slider-cfd-mobile.png"); }
                  @include media-breakpoint-up(md){ background-image:url("../Images/newhome/img/hero-slider-cfd.webp"); 
                    #{$no-webp} { background-image:url("../Images/newhome/img/hero-slider-cfd.png"); }
                  }
            }
        }
    }
}
@keyframes slick-dots-anim {
	0% { stroke-dasharray:0 100; }
  100% { stroke-dasharray:100 100; } 
}