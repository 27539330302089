@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?upyjei') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?upyjei') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?upyjei') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"]:before, [class*=" icon-"]:before, [class^="icon-"]:after, [class*=" icon-"]:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-chat-bubble {
  &:before {
    content: $icon-chat-bubble;
  }
}
.icon-mail-solid {
  &:before {
    content: $icon-mail-solid;
  }
}
.icon-whatsapp-solid {
  &:before {
    content: $icon-whatsapp-solid;
  }
}
.icon-social-viber {
  &:before {
    content: $icon-social-viber;
  }
}
.icon-social-line-full {
  &:before {
    content: $icon-social-line-full;
  }
}
.icon-social-imessage {
  &:before {
    content: $icon-social-imessage;
  }
}
.icon-arrow-horizontal {
  &:before {
    content: $icon-arrow-horizontal;
  }
}
.icon-watchlist {
  &:before {
    content: $icon-watchlist;
  }
}
.icon-password {
  &:before {
    content: $icon-password;
  }
}
.icon-power {
  &:before {
    content: $icon-power;
  }
}
.icon-wifi {
  &:before {
    content: $icon-wifi;
  }
}
.icon-bubble-person {
  &:before {
    content: $icon-bubble-person;
  }
}
.icon-telegram {
  &:before {
    content: $icon-telegram;
  }
}
.icon-soicial-telegram {
  &:before {
    content: $icon-soicial-telegram;
  }
}
.icon-flame {
  &:before {
    content: $icon-flame;
  }
}
.icon-risers-fallers {
  &:before {
    content: $icon-risers-fallers;
  }
}
.icon-equalizer {
  &:before {
    content: $icon-equalizer;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-social-twitter {
  &:before {
    content: $icon-social-twitter;
  }
}
.icon-risk-management {
  &:before {
    content: $icon-risk-management;
  }
}
.icon-initiation {
  &:before {
    content: $icon-initiation;
  }
}
.icon-trading-methods {
  &:before {
    content: $icon-trading-methods;
  }
}
.icon-limit-risk {
  &:before {
    content: $icon-limit-risk;
  }
}
.icon-deposit-adjustment {
  &:before {
    content: $icon-deposit-adjustment;
  }
}
.icon-error-fill {
  &:before {
    content: $icon-error-fill;
  }
}
.icon-error {
  &:before {
    content: $icon-error;
  }
}
.icon-plus-fill {
  &:before {
    content: $icon-plus-fill;
  }
}
.icon-arrow-next {
  &:before {
    content: $icon-arrow-next;
  }
}
.icon-vision {
  &:before {
    content: $icon-vision;
  }
}
.icon-step {
  &:before {
    content: $icon-step;
  }
}
.icon-star-circle {
  &:before {
    content: $icon-star-circle;
  }
}
.icon-star {
  &:before {
    content: $icon-star;
  }
}
.icon-social-line {
  &:before {
    content: $icon-social-line;
  }
}
.icon-social-instagram {
  &:before {
    content: $icon-social-instagram;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-social-facebook {
  &:before {
    content: $icon-social-facebook;
  }
}
.icon-search1 {
  &:before {
    content: $icon-search1;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-mail {
  &:before {
    content: $icon-mail;
  }
}
.icon-lock {
  &:before {
    content: $icon-lock;
  }
}
.icon-info-circle {
  &:before {
    content: $icon-info-circle;
  }
}
.icon-help {
  &:before {
    content: $icon-help;
  }
}
.icon-check-circle {
  &:before {
    content: $icon-check-circle;
  }
}
.icon-faq-circle {
  &:before {
    content: $icon-faq-circle;
  }
}
.icon-news {
  &:before {
    content: $icon-news;
  }
}
.icon-prepare {
  &:before {
    content: $icon-prepare;
  }
}
.icon-spread {
  &:before {
    content: $icon-spread;
  }
}
.icon-knowledge {
  &:before {
    content: $icon-knowledge;
  }
}
.icon-history {
  &:before {
    content: $icon-history;
  }
}
.icon-deposit-withdraw {
  &:before {
    content: $icon-deposit-withdraw;
  }
}
.icon-deposit {
  &:before {
    content: $icon-deposit;
  }
}
.icon-withdraw {
  &:before {
    content: $icon-withdraw;
  }
}
.icon-additional {
  &:before {
    content: $icon-additional;
  }
}
.icon-bonus {
  &:before {
    content: $icon-bonus;
  }
}
.icon-download-pdf {
  &:before {
    content: $icon-download-pdf;
  }
}
.icon-futures-interestrates {
  &:before {
    content: $icon-futures-interestrates;
  }
}
.icon-futures-forex {
  &:before {
    content: $icon-futures-forex;
  }
}
.icon-futures-crypto1 {
  &:before {
    content: $icon-futures-crypto1;
  }
}
.icon-options {
  &:before {
    content: $icon-options;
  }
}
.icon-stocks {
  &:before {
    content: $icon-stocks;
  }
}
.icon-commodities {
  &:before {
    content: $icon-commodities;
  }
}
.icon-forex {
  &:before {
    content: $icon-forex;
  }
}
.icon-indices {
  &:before {
    content: $icon-indices;
  }
}
.icon-etfs {
  &:before {
    content: $icon-etfs;
  }
}
.icon-webApp {
  &:before {
    content: $icon-webApp;
  }
}
.icon-wallet-coin {
  &:before {
    content: $icon-wallet-coin;
  }
}
.icon-cannabis {
  &:before {
    content: $icon-cannabis;
  }
}
.icon-Singapore {
  &:before {
    content: $icon-Singapore;
  }
}
.icon-Devices {
  &:before {
    content: $icon-Devices;
  }
}
.icon-zero-percent {
  &:before {
    content: $icon-zero-percent;
  }
}
.icon-IslamicSquareFull {
  &:before {
    content: $icon-IslamicSquareFull;
  }
}
.icon-IslamicSquareSmall {
  &:before {
    content: $icon-IslamicSquareSmall;
  }
}
.icon-IslamicSquareBig {
  &:before {
    content: $icon-IslamicSquareBig;
  }
}
.icon-IslamicMoon {
  &:before {
    content: $icon-IslamicMoon;
  }
}
.icon-bell-o {
  &:before {
    content: $icon-bell-o;
  }
}
.icon-bitcoin {
  &:before {
    content: $icon-bitcoin;
  }
}
.icon-futures-interestrates1 {
  &:before {
    content: $icon-futures-interestrates1;
  }
}
.icon-futures-forex1 {
  &:before {
    content: $icon-futures-forex1;
  }
}
.icon-futures-metals {
  &:before {
    content: $icon-futures-metals;
  }
}
.icon-futures-agriculture {
  &:before {
    content: $icon-futures-agriculture;
  }
}
.icon-futures-crypto {
  &:before {
    content: $icon-futures-crypto;
  }
}
.icon-futures-energy {
  &:before {
    content: $icon-futures-energy;
  }
}
.icon-futures-equityindex {
  &:before {
    content: $icon-futures-equityindex;
  }
}
.icon-futures-equityindices {
  &:before {
    content: $icon-futures-equityindices;
  }
}
.icon-futures-articles {
  &:before {
    content: $icon-futures-articles;
  }
}
.icon-futures-video {
  &:before {
    content: $icon-futures-video;
  }
}
.icon-futures-strategies {
  &:before {
    content: $icon-futures-strategies;
  }
}
.icon-futures-plus {
  &:before {
    content: $icon-futures-plus;
  }
}
.icon-futures-benefits {
  &:before {
    content: $icon-futures-benefits;
  }
}
.icon-icon-IBs {
  &:before {
    content: $icon-icon-IBs;
  }
}
.icon-options1 {
  &:before {
    content: $icon-options1;
  }
}
.icon-stocks1 {
  &:before {
    content: $icon-stocks1;
  }
}
.icon-crypto {
  &:before {
    content: $icon-crypto;
  }
}
.icon-commodities1 {
  &:before {
    content: $icon-commodities1;
  }
}
.icon-forex1 {
  &:before {
    content: $icon-forex1;
  }
}
.icon-indices1 {
  &:before {
    content: $icon-indices1;
  }
}
.icon-etfs1 {
  &:before {
    content: $icon-etfs1;
  }
}
.icon-webApp1 {
  &:before {
    content: $icon-webApp1;
  }
}
.icon-lamp7-glow {
  &:before {
    content: $icon-lamp7-glow;
  }
}
.icon-wallet-coin-color .path1 {
  &:before {
    content: $icon-wallet-coin-color-path1;
    color: rgb(0, 77, 153);
  }
}
.icon-wallet-coin-color .path2 {
  &:before {
    content: $icon-wallet-coin-color-path2;
    margin-left: -0.59765625em;
    color: rgb(255, 179, 25);
  }
}
.icon-wallet-coin-color .path3 {
  &:before {
    content: $icon-wallet-coin-color-path3;
    margin-left: -0.59765625em;
    color: rgb(255, 179, 25);
  }
}
.icon-wallet-coin-color .path4 {
  &:before {
    content: $icon-wallet-coin-color-path4;
    margin-left: -0.59765625em;
    color: rgb(255, 179, 25);
  }
}
.icon-wallet-coin-color .path5 {
  &:before {
    content: $icon-wallet-coin-color-path5;
    margin-left: -0.59765625em;
    color: rgb(255, 179, 25);
  }
}
.icon-wallet-coin-color .path6 {
  &:before {
    content: $icon-wallet-coin-color-path6;
    margin-left: -0.59765625em;
    color: rgb(255, 179, 25);
  }
}
.icon-wallet-coin1 {
  &:before {
    content: $icon-wallet-coin1;
  }
}
.icon-cannabis1 {
  &:before {
    content: $icon-cannabis1;
  }
}
.icon-Singapore1 {
  &:before {
    content: $icon-Singapore1;
  }
}
.icon-Devices1 {
  &:before {
    content: $icon-Devices1;
  }
}
.icon-zero-percent1 {
  &:before {
    content: $icon-zero-percent1;
  }
}
.icon-IslamicSquareFull1 {
  &:before {
    content: $icon-IslamicSquareFull1;
  }
}
.icon-IslamicSquareSmall1 {
  &:before {
    content: $icon-IslamicSquareSmall1;
  }
}
.icon-IslamicSquareBig1 {
  &:before {
    content: $icon-IslamicSquareBig1;
  }
}
.icon-IslamicMoon1 {
  &:before {
    content: $icon-IslamicMoon1;
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}
.icon-social-youtube {
  &:before {
    content: $icon-social-youtube;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down;
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up;
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;
  }
}
.icon-menu1 {
  &:before {
    content: $icon-menu1;
  }
}
.icon-arrow-back {
  &:before {
    content: $icon-arrow-back;
  }
}
.icon-moon-fill {
  &:before {
    content: $icon-moon-fill;
  }
}
.icon-night {
  &:before {
    content: $icon-night;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-sun {
  &:before {
    content: $icon-sun;
  }
}
.icon-loop2 {
  &:before {
    content: $icon-loop2;
  }
}
.icon-tab {
  &:before {
    content: $icon-tab;
  }
}
.icon-facebook2 {
  &:before {
    content: $icon-facebook2;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp;
  }
}
.icon-appleinc {
  &:before {
    content: $icon-appleinc;
  }
}
.icon-android {
  &:before {
    content: $icon-android;
  }
}
.icon-windows8 {
  &:before {
    content: $icon-windows8;
  }
}
.icon-linkedin2 {
  &:before {
    content: $icon-linkedin2;
  }
}
.icon-social-linkedin {
  &:before {
    content: $icon-social-linkedin;
  }
}
.icon-chrome {
  &:before {
    content: $icon-chrome;
  }
}
.icon-firefox {
  &:before {
    content: $icon-firefox;
  }
}
.icon-edge {
  &:before {
    content: $icon-edge;
  }
}
.icon-safari {
  &:before {
    content: $icon-safari;
  }
}
.icon-file-pdf {
  &:before {
    content: $icon-file-pdf;
  }
}
.icon-droplet {
  &:before {
    content: $icon-droplet;
  }
}
.icon-bullhorn {
  &:before {
    content: $icon-bullhorn;
  }
}
.icon-book {
  &:before {
    content: $icon-book;
  }
}
.icon-library2 {
  &:before {
    content: $icon-library2;
  }
}
.icon-graduation {
  &:before {
    content: $icon-graduation;
  }
}
.icon-file-check {
  &:before {
    content: $icon-file-check;
  }
}
.icon-file-text22 {
  &:before {
    content: $icon-file-text22;
  }
}
.icon-file-presentation {
  &:before {
    content: $icon-file-presentation;
  }
}
.icon-file-stats {
  &:before {
    content: $icon-file-stats;
  }
}
.icon-certificate {
  &:before {
    content: $icon-certificate;
  }
}
.icon-coins {
  &:before {
    content: $icon-coins;
  }
}
.icon-coin-dollar {
  &:before {
    content: $icon-coin-dollar;
  }
}
.icon-coin-euro {
  &:before {
    content: $icon-coin-euro;
  }
}
.icon-coin-pound {
  &:before {
    content: $icon-coin-pound;
  }
}
.icon-wallet {
  &:before {
    content: $icon-wallet;
  }
}
.icon-credit-card {
  &:before {
    content: $icon-credit-card;
  }
}
.icon-phone2 {
  &:before {
    content: $icon-phone2;
  }
}
.icon-phone3 {
  &:before {
    content: $icon-phone3;
  }
}
.icon-envelop3 {
  &:before {
    content: $icon-envelop3;
  }
}
.icon-envelop5 {
  &:before {
    content: $icon-envelop5;
  }
}
.icon-location {
  &:before {
    content: $icon-location;
  }
}
.icon-location3 {
  &:before {
    content: $icon-location3;
  }
}
.icon-clock {
  &:before {
    content: $icon-clock;
  }
}
.icon-clock3 {
  &:before {
    content: $icon-clock3;
  }
}
.icon-bell2 {
  &:before {
    content: $icon-bell2;
  }
}
.icon-calendar4 {
  &:before {
    content: $icon-calendar4;
  }
}
.icon-display4 {
  &:before {
    content: $icon-display4;
  }
}
.icon-mobile2 {
  &:before {
    content: $icon-mobile2;
  }
}
.icon-bubble8 {
  &:before {
    content: $icon-bubble8;
  }
}
.icon-bubble-dots3 {
  &:before {
    content: $icon-bubble-dots3;
  }
}
.icon-bubble-lines3 {
  &:before {
    content: $icon-bubble-lines3;
  }
}
.icon-users {
  &:before {
    content: $icon-users;
  }
}
.icon-collaboration {
  &:before {
    content: $icon-collaboration;
  }
}
.icon-search2 {
  &:before {
    content: $icon-search2;
  }
}
.icon-search3 {
  &:before {
    content: $icon-search3;
  }
}
.icon-enlarge7 {
  &:before {
    content: $icon-enlarge7;
  }
}
.icon-lock2 {
  &:before {
    content: $icon-lock2;
  }
}
.icon-safe {
  &:before {
    content: $icon-safe;
  }
}
.icon-cog2 {
  &:before {
    content: $icon-cog2;
  }
}
.icon-cog3 {
  &:before {
    content: $icon-cog3;
  }
}
.icon-stats-dots {
  &:before {
    content: $icon-stats-dots;
  }
}
.icon-stats-bars3 {
  &:before {
    content: $icon-stats-bars3;
  }
}
.icon-chart {
  &:before {
    content: $icon-chart;
  }
}
.icon-stats-growth {
  &:before {
    content: $icon-stats-growth;
  }
}
.icon-stats-growth2 {
  &:before {
    content: $icon-stats-growth2;
  }
}
.icon-medal {
  &:before {
    content: $icon-medal;
  }
}
.icon-gift {
  &:before {
    content: $icon-gift;
  }
}
.icon-gift2 {
  &:before {
    content: $icon-gift2;
  }
}
.icon-lamp7 {
  &:before {
    content: $icon-lamp7;
  }
}
.icon-lamp8 {
  &:before {
    content: $icon-lamp8;
  }
}
.icon-briefcase {
  &:before {
    content: $icon-briefcase;
  }
}
.icon-shield {
  &:before {
    content: $icon-shield;
  }
}
.icon-shield-check {
  &:before {
    content: $icon-shield-check;
  }
}
.icon-clipboard2 {
  &:before {
    content: $icon-clipboard2;
  }
}
.icon-menu7 {
  &:before {
    content: $icon-menu7;
  }
}
.icon-earth2 {
  &:before {
    content: $icon-earth2;
  }
}
.icon-earth22 {
  &:before {
    content: $icon-earth22;
  }
}
.icon-link5 {
  &:before {
    content: $icon-link5;
  }
}
.icon-star-empty {
  &:before {
    content: $icon-star-empty;
  }
}
.icon-star-full {
  &:before {
    content: $icon-star-full;
  }
}
.icon-star-empty3 {
  &:before {
    content: $icon-star-empty3;
  }
}
.icon-star-full2 {
  &:before {
    content: $icon-star-full2;
  }
}
.icon-thumbs-up2 {
  &:before {
    content: $icon-thumbs-up2;
  }
}
.icon-pointer {
  &:before {
    content: $icon-pointer;
  }
}
.icon-touch {
  &:before {
    content: $icon-touch;
  }
}
.icon-warning {
  &:before {
    content: $icon-warning;
  }
}
.icon-warning2 {
  &:before {
    content: $icon-warning2;
  }
}
.icon-plus3 {
  &:before {
    content: $icon-plus3;
  }
}
.icon-minus3 {
  &:before {
    content: $icon-minus3;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-cross2 {
  &:before {
    content: $icon-cross2;
  }
}
.icon-cross3 {
  &:before {
    content: $icon-cross3;
  }
}
.icon-checkmark {
  &:before {
    content: $icon-checkmark;
  }
}
.icon-checkmark4 {
  &:before {
    content: $icon-checkmark4;
  }
}
.icon-enter3 {
  &:before {
    content: $icon-enter3;
  }
}
.icon-play3 {
  &:before {
    content: $icon-play3;
  }
}
.icon-arrow-up3 {
  &:before {
    content: $icon-arrow-up3;
  }
}
.icon-arrow-up4 {
  &:before {
    content: $icon-arrow-up4;
  }
}
.icon-arrow-right3 {
  &:before {
    content: $icon-arrow-right3;
  }
}
.icon-arrow-right4 {
  &:before {
    content: $icon-arrow-right4;
  }
}
.icon-arrow-down3 {
  &:before {
    content: $icon-arrow-down3;
  }
}
.icon-arrow-down4 {
  &:before {
    content: $icon-arrow-down4;
  }
}
.icon-arrow-left3 {
  &:before {
    content: $icon-arrow-left3;
  }
}
.icon-arrow-left4 {
  &:before {
    content: $icon-arrow-left4;
  }
}
.icon-circle-right2 {
  &:before {
    content: $icon-circle-right2;
  }
}
.icon-circle-left2 {
  &:before {
    content: $icon-circle-left2;
  }
}
.icon-circle-right3 {
  &:before {
    content: $icon-circle-right3;
  }
}
.icon-circle-left3 {
  &:before {
    content: $icon-circle-left3;
  }
}
.icon-filter3 {
  &:before {
    content: $icon-filter3;
  }
}
.icon-filter4 {
  &:before {
    content: $icon-filter4;
  }
}
.icon-page-break2 {
  &:before {
    content: $icon-page-break2;
  }
}