﻿// Instruments Feeds Widget
$feeds-items-base-index: 9; // base index on which we relay for layering the items
$button-width: 3.25rem;
$button-height: 2.75rem;
$button-width-selected: 3.25rem;
$button-height-selected: 3rem;
$widget-radius: 1.5rem;
.feed-table { display:flex; flex-direction:column;
    @include media-breakpoint-down(md){ flex-direction:column; }
    @include media-breakpoint-up(lg){ max-width:31rem; }
    h2 { margin-bottom:$spacer * .5; margin-top:$spacer; font-size: 1.375rem; font-weight: $font-weight-bold; color: $medium-dark-blue; text-align:left;
        @include media-breakpoint-up(md){ margin-bottom:$spacer; margin-top:0; font-size:1.5rem; padding:0; line-height:$line-height-tight; }
    } 
    @include instrument-icon(1.875rem);
    &.feeds-top-tabs { 
        .instruments-widget-tabs { max-width:calc(100% - $widget-radius); flex-direction:row; flex:0 1 auto;
          &:before { width:calc(100% + $widget-radius); height:1.75rem; left:0; right:0; top:100%; bottom:auto; border-radius:0 $widget-radius 0 0; }
          @for $i from 4 through 9 {
              &.categories-#{$i} button { margin-left:-($i - 2px); }
        }
        button { border-radius:.75rem .75rem 0 0; margin:0 0 -3px 0;
            &:first-child { margin-left:0; }
            img { margin-right:0; }
        }
      }
    }
    .instruments-table { display:flex; flex:1 1 100%; flex-direction:column; justify-content:space-between; color:$color-main; }
    .instrument-item { margin-bottom:$spacer; height:2.5rem;
        display:grid; font-size:.875rem; grid-template-columns:3rem 1.5fr 1fr; grid-template-rows:1fr 1fr; grid-auto-flow:column; align-items:center; justify-items:start; padding-left:0; padding-right:0;
        @include media-breakpoint-up(md){ grid-template-columns:3rem minmax(2rem,2fr) 3fr 1fr; grid-template-rows:none; justify-items:center; }
        @include media-breakpoint-up(lg){ font-size: .9375rem;}
        @include media-breakpoint-up(xl){ font-size: 1rem; }
        a { font-weight:$font-weight-medium; }
        .trade-button { --bs-btn-color:#{$dark-blue}; --bs-btn-border-color:#{$dark-blue}; --bs-btn-hover-bg:#{$dark-blue}; --bs-btn-hover-border-color:#{$dark-blue}; 
            --bs-btn-active-bg:#{$dark-blue}; --bs-btn-active-border-color:#{$dark-blue}; --bs-btn-border-radius:3rem; --bs-btn-border-width:1px; white-space:nowrap;
            @include media-breakpoint-up(md){ padding:($spacer * .5) $spacer;}
            @include media-breakpoint-up(xl){ font-size:.9375rem; }            
            &:focus, &:hover { text-decoration: none; }
        }        
        .inst-name-wrapper { display:flex; justify-self:start; line-height:$line-height-tight;            
            @include media-breakpoint-down(md){ max-width:90%; grid-column:span 2; }
            a { color:$dark-blue;
                &:focus, &:hover { color:$dark-blue; text-decoration:underline; }
                @include media-breakpoint-down(md){ text-overflow:ellipsis; overflow:hidden; white-space:nowrap; }
            }
            @include media-breakpoint-up(md){ @include multiLineElippsis(2); word-break:break-word; }
            sup { margin-left:$spacer * .125; top:-.125rem; font-size:.75rem;
                span::before { color:$color-blue; }
            }
        }
        span {
            &.sell, &.buy, &.rate-change, &.trade { text-align:center; padding-left:$spacer * .25; padding-right:$spacer * .25; }
            &.red span, &.inst-down span  { color:$color-down; }
            &.green span, &.inst-up span { color:$color-up; }
            &.rate-change {
                .highlight-threshold { border-radius:.25rem; color:$white; display:inline-block; min-width:3.75rem; padding:($spacer * .125) ($spacer * .5);
                    @include media-breakpoint-up(lg){ min-width:4.5rem; }
                }
                &.inst-up .highlight-threshold { background-color:$color-up }
                &.inst-down .highlight-threshold  { background-color:$color-down; }
            }
        }
        .sell-buy-grid { display:grid; grid-template-columns:1fr 1fr; width:100%; align-items:center;
            > span:first-child { @include media-breakpoint-down(md){ text-align:left; padding-left:0; } }
        }
        .instrument-basic-info { justify-self:start;
            @include media-breakpoint-down(md) { grid-row:span 2; }
        }
        .sell,.buy,.change { text-align:center; }
        .trade { justify-self:end;
            @include media-breakpoint-down(md) { grid-row:span 2; }
        }
    }
    &.mobile-view .instrument-item {
        display:grid; font-size:.875rem; grid-template-columns:3rem 1.5fr 1fr; grid-template-rows:1fr 1fr; grid-auto-flow:column; align-items:center; justify-items:start; padding-left:0; padding-right:0;
        .inst-name-wrapper { max-width:90%; grid-column:span 2; white-space:nowrap; text-overflow:ellipsis; overflow:hidden; 
            a { display:block; text-overflow:ellipsis; overflow:hidden; white-space:nowrap; }
        }
        .instrument-basic-info { grid-row:span 2; }
        .sell-buy-grid > span:first-child { text-align:left; padding-left:0; }
        .trade { grid-row:span 2; }
    }
}
.instruments-widget {
    &-tabs { position:relative; max-width:calc(100% - $widget-radius); display:flex; align-items:flex-end; flex:0 1 auto;  
        @include media-breakpoint-up(md){ flex-direction: column; flex: 0 1 15%; max-width: 15%; }
        &:before { position:absolute; background-color:$white; width:calc(100% + $widget-radius); height:1.75rem; left:0; right:0; top:100%; content:""; margin:auto; border-radius:0 $widget-radius 0 0; z-index: $feeds-items-base-index + 7;
            @include media-breakpoint-up(md){ width:1.375rem; height:100%; top:0; left:98%; bottom:0; border-radius:0 0 0 $widget-radius; }
            @include media-breakpoint-up(xl){ width:1.5rem; }
        }
        @include media-breakpoint-down(md){
            @for $i from 4 through 9 {
                &.categories-#{$i} button { margin-left:-($i - 2px); }
            }
        }       
        button { position:relative; background-color:#f8f8f8; display:flex; justify-content:center; align-items:center; margin:0 0 -3px 0; border-radius:.75rem .75rem 0 0; box-shadow:0 .875rem 3rem 0 rgba(24, 74, 181, .5);
            width: $button-width - .25rem; height: $button-height - .5rem; transition: background-color .5s ease-in-out;
            @include media-breakpoint-up(media, (media:480px)){ width: $button-width; height: $button-height; }
            @include media-breakpoint-up(md){ height:$button-height + .25rem; margin:-($spacer * .5) 0 0 0; border-radius:.75rem 0 0 .75rem; box-shadow:-.875rem -.5rem 3rem 0 rgba(24,74,181,.5);
              .inner & { box-shadow: 0 0 2rem 0 rgba(24,74,181,.25); }
            }
            &:hover, &:focus { cursor: pointer; background-color: $white; }
            &:first-child { z-index:1; margin-left:0;
              @include media-breakpoint-up(md){ margin-top:0; }
            }
            @for $i from 1 through $feeds-items-base-index { 
                $itemIndex:$i - 1;
                &:nth-last-child(#{$i}) { z-index:$i; }
            }
            &.selected { background-color:$white; box-shadow:0 0 .625rem .4375rem rgba(24,74,181,.15); z-index:$feeds-items-base-index + 4;
                width: $button-width-selected - .125rem; height:$button-height-selected;
                @include media-breakpoint-up(media, (media:480px)){ width:$button-width-selected + .25rem; height:$button-height-selected + .5rem; }
                @include media-breakpoint-up(md){ width:$button-width-selected + 1rem; box-shadow:0 0 .625rem 0 rgba(24,74,181,.25); }
                img {
                    @include media-breakpoint-up(md){ margin-right:$spacer * -.5; }
                }
            }
            img { width: 1.25rem; height: 1.25rem;
                @include media-breakpoint-up(media, (media:480px)){ width:1.375rem; height:1.375rem; }
                @include media-breakpoint-up(md){ width:1.5rem; height:1.5rem; margin-right:$spacer * .25; }
                @include media-breakpoint-up(lg){ margin-right:$spacer *.5; }
            }
            .tab-icon { color:$dark-blue; 
                @include media-breakpoint-up(md){ font-size:1.25rem; }
            }
            &.selected .tab-icon { font-size:1.25rem; color:$medium-dark-blue;
                @include media-breakpoint-up(md){ font-size:1.5rem; }
            }
        }
    }
    &-items { flex:1 0 100%; padding:($spacer * .5) ($spacer * .5) 0; background-color:$white; border-radius:0 0 1.5rem 1.5rem; flex:1 0 100%; box-shadow:0 1.75rem 3.5rem scale-color($cfd-blue, $alpha:-66%); min-height:31rem; z-index:$feeds-items-base-index + 2;         
        @include media-breakpoint-up(md){ border-radius:0 1.5rem 1.5rem 1.5rem; padding: ($spacer * 1.5) ($spacer * 1.5) 0; min-height:30rem; }        
    }
}
.feeds-widget-disclaimer { text-align:center; margin:$spacer * 2 0; padding:0 ($spacer * .75); } 