﻿// Mixins
$RTL: false;
@mixin styled-scrollbar() {
	@media (hover: hover) and (pointer: fine) {
		&::-webkit-scrollbar {
			background-color: transparent;
		}

		&::-webkit-scrollbar-track {
			background-color: transparent;
			margin: ($spacer * .25) 0;
			border-radius: 0 0 $border-radius-large $border-radius-large;
		}

		&::-webkit-scrollbar-thumb {
			background: $dark-blue;
			border: 2px solid $white;
			border-radius: $border-radius-large;
		}
	}
}
@mixin styled-container-scrollbar(){
    @media (hover: hover) and (pointer: fine) {
        &::-webkit-scrollbar { background-color:transparent; max-height:.5rem; }
        &::-webkit-scrollbar-track { background-color:transparent; border-radius: 0 0 $border-radius-large $border-radius-large; }
        &::-webkit-scrollbar-thumb { background:scale-color($light-gray, $alpha:-70%);  border-radius:$border-radius-large; }
    }
}

@mixin multiLineElippsis($rowsToShow) {
    display: -webkit-box;
    -webkit-line-clamp:$rowsToShow;
    -webkit-box-orient:vertical;
    overflow:hidden;
    text-overflow:ellipsis;
}

@mixin rm-click-hint {
	//FYI: remove annoying tap hinting on touch devices (Mainly android)
	@media (pointer:coarse) {
		-webkit-tap-highlight-color: transparent;
	}
}
@mixin instrument-icon($font-size:2.5rem, $border-radius:.5rem) {
	.instrument-basic-info { display:flex; align-items:center;
		figure { width:1em; height:1em; margin:0 ($spacer * .75) 0 0; font-size:$font-size; border-radius:$border-radius; overflow:hidden; flex:0 0 auto;
			img { display:block; width:100%; height:100%; opacity:0; transition:opacity .15s ease-in-out; }
			&.show-fallback { display:flex; background:scale-color($gray-700, $alpha:-92%); justify-content:center; align-items:center;
				&:before { font-size:.5em; }
				img { display:none; }
			}
			&.show-icon img{ opacity:1; }
		}
	}
}

@function generate-svg($width, $height, $fill, $path) {
	@return "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 #{$width} #{$height}'><path d='#{$path}' fill='#{$fill}' /></svg>";
}
@function rtlFlip($ltrValue, $rtlValue) {
	//@debug $RTL; // from GULP Header
	@if $RTL {
		@return $rtlValue;
	}
	@else {
		@return $ltrValue;
	}
}

@mixin position(
  $position,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null
) {
  position: $position;
  @if $top != null {
    top: $top;
  }
  @if $right != null {
    right: $right;
  }
  @if $bottom != null {
    bottom: $bottom;
  }
  @if $left != null {
    left: $left;
  }
}

@mixin background-plus($size, $position, $top, $right, $bottom, $left) {
	height: $size; width: $size; margin:auto;
	@include position($position, $top, $right, $bottom, $left);
}