﻿/// <reference path="_variables.scss" />
// scss-docs-start import-stack
// Configuration
@import "../../../node_modules/bootstrap/scss/functions";
$enable-shadows:              true ;
$enable-caret:true;

$font-family-base: Roboto, Rubik, Tajawal, system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
$font-family-title: Poppins, Roboto, Rubik, system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;

//$grid-gutter-width: .75rem;

$grid-breakpoints: ( 
	xs:		0,
	sm:		1px,//576px,
	md:		768px,
	lg:		1024px,
	xl:		1280px,
	xxl:	1400px,
	xxxl:	1600px
);

/*$breakpoints: (
  small: 0,
  medium: 768px,
  large: 1024px,
  xlarge: 1280px,
  xxlarge: 1440px,
  xxxlarge: 1600px
);*/


$container-max-widths: (
//xs: 575px,
sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px, );

$mbp: md; // major breakpoint

//$container-padding-x: 1rem;

// Fonts
$font-weight-lighter: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-bolder: 700;
$font-weight-extra-bold: 800;
$font-weight-extra-bolder: 900;

// Style anchor elements.

//$link-color:				$primary !default;
$link-decoration:			none;
$link-hover-decoration:		none;

// Buttons
$btn-border-radius:			.5rem;
$btn-border-radius-sm:		.25rem;
$btn-border-radius-lg:		.75rem;
$btn-border-width:			0;
$btn-focus-width:			0;

// Dropdown
$dropdown-dark-color:               $white;
$dropdown-dark-bg:                  $dark-blue;
//$dropdown-dark-border-color:        $dropdown-border-color !default;
$dropdown-dark-divider-bg:          $light-blue;
$dropdown-dark-box-shadow:          $box-shadow;
//$dropdown-dark-link-color:          $dropdown-dark-color !default;
//$dropdown-dark-link-hover-color:    $white !default;
//$dropdown-dark-link-hover-bg:       rgba($white, .15) !default;
//$dropdown-dark-link-active-color:   $dropdown-link-active-color !default;
//$dropdown-dark-link-active-bg:      $dropdown-link-active-bg !default;
//$dropdown-dark-link-disabled-color: $gray-500 !default;
$dropdown-dark-header-color:       $cyan;



// Navbar
//$navbar-dark-color:                 rgba($white, .55) !default;
//$navbar-dark-hover-color:           rgba($white, .75) !default;
//$navbar-dark-active-color:          $white !default;
//$navbar-dark-disabled-color:        rgba($white, .25) !default;
//$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
//$navbar-dark-toggler-border-color:  rgba($white, .1) !default;
$navbar-light-color:                $dark-blue;
$navbar-light-hover-color:          $light-blue;
$navbar-light-active-color:          $light-blue;
//$navbar-light-disabled-color:       rgba($black, .3) !default;
//$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
//$navbar-light-toggler-border-color: rgba($black, .1) !default;
//
//$navbar-light-brand-color:                $navbar-light-active-color !default;
//$navbar-light-brand-hover-color:          $navbar-light-active-color !default;
//$navbar-dark-brand-color:                 $navbar-dark-active-color !default;
//$navbar-dark-brand-hover-color:           $navbar-dark-active-color !default;

// Tabs
//$nav-tabs-border-color:             $gray-300;
//$nav-tabs-border-width:             $border-width;
//$nav-tabs-border-radius:            $border-radius;
//$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color;
//$nav-tabs-link-active-color:        $gray-700;
//$nav-tabs-link-active-bg:           $body-bg;
//$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

//$table-striped-bg-factor:     .3;


@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/variables-dark";


$btn-focus-box-shadow:		null; // to override !default with null you need to overrride after the variable is declared
$btn-active-box-shadow:		null;

@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/utilities";

// Offcanvas
$offcanvas-bg-color:$dark-blue;

// Layout & components
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/tables";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/buttons";
@import "../../../node_modules/bootstrap/scss/transitions";
@import "../../../node_modules/bootstrap/scss/dropdown";
@import "../../../node_modules/bootstrap/scss/button-group";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/navbar";
@import "../../../node_modules/bootstrap/scss/card";
@import "../../../node_modules/bootstrap/scss/accordion";
//@import "../../../node_modules/bootstrap/breadcrumb";
@import "../../../node_modules/bootstrap/scss/pagination";
@import "../../../node_modules/bootstrap/scss/badge";
@import "../../../node_modules/bootstrap/scss/alert";
@import "../../../node_modules/bootstrap/scss/progress";
@import "../../../node_modules/bootstrap/scss/list-group";
@import "../../../node_modules/bootstrap/scss/close";
@import "../../../node_modules/bootstrap/scss/toasts";
@import "../../../node_modules/bootstrap/scss/modal";
@import "../../../node_modules/bootstrap/scss/tooltip";
@import "../../../node_modules/bootstrap/scss/popover";
//@import "../../../node_modules/bootstrap/scss/carousel";
//@import "../../../node_modules/bootstrap/scss/spinners";
@import "../../../node_modules/bootstrap/scss/offcanvas";
@import "../../../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack

// Custom Modal
@import "components/modal";
// Cookies banner
@import "components/cookie-banner-sticky";