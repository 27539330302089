﻿@use "sass:math";
.simplify { min-height:40em; padding-bottom:8em;
	@include media-breakpoint-up(xl){ padding-top:5em; }
	h2, h3 { text-align:center; }
	h2 { margin-bottom: 1rem; }
	h3 { font-size:1.25rem; font-weight:$font-weight-light; margin:1rem 0; }
	.orbit { 
		@include media-breakpoint-up(md){ margin:2em; } 
		&-container { outline:none !important; } // height: auto !important;
		&-slide { top: 0; opacity: 0;
			//&.is-active { opacity:1; position:static; }
			&.fade {
				//&-in { @include mui-fade(in, $duration: 1s, $timing: ease-out); }
				//&-out { @include mui-fade(out, $duration: 1s, $timing: ease-in); }  
			}
		}
		ul {  @include media-breakpoint-up(md){ overflow: visible; }}
		figure { margin-bottom:1rem;
			@include media-breakpoint-up(md){ display:flex; align-items:flex-start;
				div { flex:0 0 percentage(math.div(8, 12));
					img { border-radius: 1rem; }
				}
			}
			img { width:100%; border-radius:.5rem; background-color: rgba(0, 0, 0, .12); box-shadow: 0 .25rem 2rem rgba(0, 0, 0, .12); image-rendering: -webkit-optimize-contrast; }
			figcaption { font-size:1em; padding:0; text-align:center; font-size:.875rem; margin-top:1em;
				@include media-breakpoint-up(md){ display:flex; align-items:baseline; position:relative; text-align:initial; margin-top:0;
					@at-root #{selector-replace(#{&}, 'figure', '[data-slick-index="0"] figure')}{ top:15em; }
					@at-root #{selector-replace(#{&}, 'figure', '[data-slick-index="1"] figure')}{ top:1em; }
					@at-root #{selector-replace(#{&}, 'figure', '[data-slick-index="2"] figure')}{ top:10em; }
					@at-root #{selector-replace(#{&}, 'figure', '[data-slick-index="3"] figure')}{ top:14em; }
					@at-root #{selector-replace(#{&}, 'figure', '[data-slick-index="4"] figure')}{ top:12em; }
					&::before { order:1; flex: 0 0 10%; content:""; display:block; height:.5em; border-top:2px dashed #2096d0; }
					&::after { order:2; flex: 0 0 1em; content:""; display:block; height:1em; width:1em; margin-#{$align-opp}:1em;  border:2px solid #2096d0; border-radius:.5em; }
					p, ul, div { order: 3; font-size:1em; }
				}
				@include media-breakpoint-up(lg){ 
					&::before { flex: 0 0 20%; }
					 p, ul, div { font-size:1.125em; }
				}
				ul { display:flex; margin:1rem 0; padding:0; justify-content:center; flex-wrap:wrap; list-style: none;
					@include media-breakpoint-up(md){ display:block; }
					li { flex:0 1 auto; padding:($spacer * .5) $spacer; line-height:1.1;
						@include media-breakpoint-up(md){ flex:1 0 auto; padding:0 $spacer ($spacer * .25); line-height:1.3; }
						&::before { content:"+"; color:$color-blue;  margin-right:.5rem; left:0; }
					}
				}
			}
		}
		&-bullets { 
			@include media-breakpoint-up(md){ width:percentage(math.div(8, 12)); margin-top:2rem; }
			button { background-color: #e5e5e5; height:.625rem; width:.625rem; cursor:pointer; margin:0 .5em;
				&.is-active { background-color: #2096d0; height:.875rem; width:.875rem; }
			}
		}
	}
	.dots-wrapper { margin:$spacer 0; }
	.slick-dots { position:relative; }
}