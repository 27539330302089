﻿.dropdown-toggle-search {
		@include media-breakpoint-down(lg){ position:static; }
		&-icon { align-items: center; display:flex; justify-content:center; height:1.5rem; width:1.5rem;
			&:before { background-image:$search-toggle-icon; background-repeat:no-repeat; background-position:50%; display:inline-block; width:100%; height:100%; content:"";
				@at-root .show #{&} { background-image:$search-toggle-icon-white; }
			}
		}
	@include instrument-icon(1.875rem);
	.instrument-basic-info {
			.icon-with-border { border:1px solid scale-color($white, $alpha:-75%); }
			figure.show-fallback { background:scale-color($white, $alpha:-25%); }
		}
		.btn-link { background:center center / 1.5rem $search-toggle-icon no-repeat; padding:$spacer * 1.3125; height:1.5rem; width:1.5rem; font-size:1.5rem;
			&:focus-visible { box-shadow:0 0 0 .25rem rgba(13,110,253,.25); outline: 0; }
			&.show { background-image:$search-toggle-icon-white; background-color:$dark-blue; border-radius:.5rem .5rem 0 0; }
		}
		.dropdown-toggle:after { display:none; }
		.dropdown-menu { width:22.5rem; background-color: $dark-blue; box-shadow: 0 3.125rem 3.125rem rgba(8, 20, 60, .65); border: none; border-radius: 0 0 .5rem .5rem; padding: 1.5rem;
			> div { width:100%; }
			&.show { display: flex; margin-top:-($spacer * .125); 
				@include media-breakpoint-down(lg){ margin-top:-.625rem; width:100%; }
			}
		}
		.input-group { position: relative;
			&:focus-within .input-group-icon { display: none; }
			input { border: none; font-size: 1rem; font-weight: $font-weight-regular; margin: 0; width: 100%; height: 2.75rem; border-radius: 2rem !important; padding: .5rem 1rem;
				&:focus { background-color: $white; outline: none;
					+ .input-group-icon { display: none; }
				}
				&::placeholder { color: $light-grey; }
				+ .input-group-icon { background: escape-svg($search-input-icon) no-repeat 50%; height: 1.125rem; width: 1.125rem; position: absolute; #{$align-opp}: .75rem; top: 0; bottom: 0; margin: auto 0; } 
			}
		}
		.results { margin:($spacer * 2) 0 $spacer; max-height: 19rem; overflow-y: auto; overflow-x: hidden;
			&::-webkit-scrollbar-track { background-color: transparent; }
			&::-webkit-scrollbar { width: 7px; background-color: transparent; }
			&::-webkit-scrollbar-thumb { border-radius: .5rem; background-color: rgba(24, 74, 181,.5); }
			@-moz-document url-prefix() { scrollbar-color: rgba(24, 74, 181,.5); scrollbar-width: thin; }
			.results-list { list-style: none; margin:0; padding:0 ($spacer * .5) 0 0; width: 100%; }
			.search {
				&-result, &-results-category, &-no-results-data { padding:$spacer * .5; }
				&-results-category { color:scale-color($white, $alpha:-50%); font-size: .75rem; margin-bottom:0; width:100%; }
				&-result { align-items: center; border-bottom:1px solid scale-color($white,$alpha: -75%); display: flex; justify-content: space-between; margin-bottom:$spacer * .5;
					a { color: $white;
						-webkit-user-drag: none; // FYI: prevent user dragging of javascript:void(0); from anchor href into input field
						&.link-title { flex: 0 1 100%; font-size: 1rem; margin-right:$spacer * .5; }
						&.link-trade { background-color: $white; color: $dark-blue; border-color: $white; border-radius: 1.25rem; font-size: .75rem; font-weight: $font-weight-medium; padding: .125rem .75rem; text-align: center; min-width: 4rem; flex:0 0 auto;
							@include media-breakpoint-up(lg) { background-color: transparent; border: 1px solid scale-color($white, $alpha:-50%); color: scale-color($white, $alpha:-50%);
								&[v-cloak] { background-color:$white; }
								&:focus { background-color: $white; color: $dark-blue; border-color: $white; }
								@media(hover:hover) {
									&:hover { background-color: $white; color: $dark-blue; border-color: $white; }
								}
							}
						}
					}
				}
				&-no-results {
					&-data {
						span { color: $white; }
						a { color: $light-green; }
					}
				}
			}
			.highlight-search { color: $light-blue; }
	
			[v-cloak], .placeholder-container { display:none; } // hide all search results
			[v-cloak] ~ .placeholder-container { display:block;  } // show the placeholder container
			[v-cloak] ~ .placeholder-container .placeholder { background-color:scale-color($white,$alpha: -50%); height:1.375rem; border-radius:1rem;  // style placeholders
				&.bg-light-blue { background-color:scale-color($light-blue,$alpha: -50%); }
			}
		}
}
