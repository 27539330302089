.trustpilot {
    &-section { position:relative; margin-bottom:$spacer * 2.5; 
        @include media-breakpoint-up(md) { margin-bottom:$spacer * 5; }
    }
    &-wrap { position:relative; padding-right:0; padding-left:0; } 
    &-bg { display:flex; justify-content:center; align-items:flex-end; padding-top:$spacer * 14; padding-bottom:0;
         #{"/*! rtl:ignore */"}background-position:35% 0; background-repeat:no-repeat; background-size:cover;
        &.lazyloaded { background-image:url("../Images/newhome/img/iPhone-transperent.png"); 
            .futureshome &{ background-image:url("../../images/futures/img/iPhone-Trustpilot.png"); } // futures path needs to go up two levels, be careful not to change the path
            .futureshome.lang-es &{ background-image:url("../../images/futures/img/iPhone-Trustpilot-es.png"); } // futures path needs to go up two levels, be careful not to change the path
            .country-id &{  background-image:url("../images/newhome/img/iPhone-transparent-id.png"); /*! rtl:ignore */ }
        }
        @include media-breakpoint-up(md) { padding-top:26%; padding-bottom:$spacer * 2; background-size:contain; }
        &:after { background-image:linear-gradient(180deg, scale-color($white, $alpha:-100%) 30%, scale-color($white, $alpha:0%)); position:absolute; top:0; left:0; height:100%; width:100%; content:""; z-index:0; }
    }
    .plus-bg { z-index:1;
        &-small { width:5.625rem; height:5.625rem; top:12%; right:-2.5rem; opacity: .4;
            @include media-breakpoint-up(md) { top:55%; right:25%; } 
        }
        &-large { display: none; width: 25rem; height: 25rem; top:-8%; left:6%;
            @include media-breakpoint-up(md) { display: block; }  
        }
    }
    &-widget { display:flex; flex-wrap:wrap; align-items:center; justify-content:center; width:17.25rem; margin:auto auto 0; #{"/*! rtl:ignore */"}direction:ltr; z-index:1;
        @include media-breakpoint-up(md){ width:20rem; }
    }
    &-logo { color:$black; display:flex; flex-wrap:wrap; align-items:baseline; justify-content:space-around; line-height:1;
        @include media-breakpoint-up(md){flex-wrap:nowrap; }
        svg { height:3.25rem; width:3.25rem; }
        strong { font-size:2.5rem; font-weight:$font-weight-medium; 
            @include media-breakpoint-up(md) { font-size:2.75rem; }
        }
        span { font-size:1.5rem; font-weight:$font-weight-light; margin-left:$spacer .5; }
    }
    .score-container { display:flex; align-items:center; justify-content:space-between; margin-top:$spacer * 1; padding:0 1rem; }    
    .reviews { display:flex; flex-direction:column; justify-content:center; flex:1 0 auto; direction:ltr;
        .title { font-size:1.125rem; display:flex; margin-bottom:0; }
        .amount { margin-left:$spacer * .25; }
        .trust-score{
            figure { margin:0; display:flex; 
                img { height:2rem; }
            }
            svg { height:auto; margin:0 auto; display:block; max-width:100%;
                .rect { fill:transparent; }
            }
        }
        .quality { color:$black; font-size:1.5rem; font-weight:$font-weight-regular; margin:($spacer * .25) auto 0; 
            @include media-breakpoint-up(md) { font-size:1.75rem; }
        }
    }
    .score { color:$black; font-size:4rem; font-weight:$font-weight-light; #{"/*! rtl:ignore */"}margin-left:$spacer; }
}