/// HOMEPAGE ///
@import "functions";
@import "variables";
@import "custom.scss";
@import "icons/icons";
@import "base";
@import "header";
@import "components/hero";
@import "home-sections/seo-carousel";
@import "../shared/sponsorship";
@import "components/feeds-widget";
@import "components/trade";
@import "components/simplified-trading";
@import "../shared/trustpilot";
@import "components/why-us";
@import "components/products";
@import "components/pay-method";
@import "components/footer";
@import "components/cookie-banner-sticky";
@import "components/regulatory-list";
@import "langspecs";
//SG Awareness
$color-aw-bg: scale-color($white, $alpha:-80%);
$color-aw-bg-exp: scale-color($white, $alpha:-60%);

.awareness { display:none; // hide for mobile ( regulatory requirement - currently only shows on SG ), this is also enforced upon rendering via isMobilePlatform on server side code
    @include media-breakpoint-up(md){ display:flex; position:absolute; top:1rem; left:3%; right:3%; background:$color-aw-bg; align-items:center; border-radius:.5rem; color:$white; cursor:pointer; overflow:hidden; max-height: 4.5rem; max-width:4.5rem; z-index:2; }
    @include media-breakpoint-up(xxl){ top:1.75rem; left:50%; margin-left:-44rem; }
    &, * { transition:all .3s ease-in-out; }
    &:before { flex:0 0 2.75rem; font-size:2.5rem; padding:1rem; }
    @mixin expanded {
        @include media-breakpoint-up(md){ max-width:34.5rem; background:$color-aw-bg-exp; }
        &:before { content:$icon-error; filter:drop-shadow(0px 0px 4px rgb(0 0 0 / 30%)); }
        p { opacity:1; left:0; }
    }
    &.expanded { @include expanded; }
    @media only screen and (hover:hover) and (pointer:fine){ &:hover { @include expanded; } }
    p { position:relative; left:-1em; flex:1 0 50%; min-width:75vw; padding:.75em 1em .75em 0; font-size:.875rem; margin:0; opacity:0; 
        text-shadow: 0 .15rem .25rem scale-color($black, $alpha:-60%);
        @include media-breakpoint-up(md){ flex:0 0 30rem; min-width:auto; font-size:1rem; }
        a { display: block; padding-top:.25em; color:$white; text-decoration:underline;
            &:hover { text-decoration:none; }
        }
    }
}

 //Trade
.trade { 
  &-section { position: relative; margin-bottom: 5rem;
    @include media-breakpoint-up(md) { margin-bottom: 9rem; }
    @include media-breakpoint-only(sm) { padding-top: 11.25rem; padding-bottom: 4.25rem; }
    .plus-bg {
      &-small { width: 5.25rem; height: 5.25rem; top:6%; #{$align-def}:4%;
          @include media-breakpoint-only(sm) { 
              &.small-only { width: 4rem; height: 4rem; top:12%; #{$align-opp}:6%; #{$align-def}:auto; } 
          } 
          @include media-breakpoint-up(md) { top:45%; #{$align-def}:2%; }
      }
      &-medium { width: 9.125rem; height: 9.125rem; top:100%; #{$align-opp}:6%;
          @include media-breakpoint-up(md) { top:95%; }
       }
      &-xlarge { width:40.25rem; height: 40.25rem; top:-18%; #{$align-def}:-34%;  
        @include media-breakpoint-only(sm) { display: none; } 
      }
    } 
  }
  &-title-bg { text-align: center; position: relative; 
     @include media-breakpoint-up(md) { padding-top: 17.5rem; padding-bottom: 6rem; }
  } 
  &-title { font-size: 1.375rem; color:$dark-blue; font-weight: $font-weight-light; line-height: $line-height-small;
      strong { font-weight: $font-weight-bold; }
      @include media-breakpoint-up(md) { font-size: 1.875rem; } 
  }
  &-data { position: relative; border-radius: $border-radius-large; box-shadow: none; 
    &:before, &:after { content: ""; position: absolute; z-index: 0; display: block; border-radius: $border-radius-large; }
    &:before { padding: 1px; border: 1px solid $white; top: 0; left: 0; right: 0; bottom: 0; background-image: linear-gradient(200deg, $white 50%, $cfd-blue 100%); }
    &:after { top: 3px; bottom: 3px; #{$align-def}: 3px; #{$align-opp}: 3px; z-index: 0; border-radius: 1.25rem; background: $white; }
    @include media-breakpoint-up(md) { box-shadow: 0 34px 68px rgba(0, 0, 0, 0.13); 
       &:before { border-width: 10px; padding: 10px; background-image: linear-gradient(45deg, $white 50%, $cfd-blue 100%); }
       &:after { top: 11px; bottom: 11px; #{$align-def}: 11px; #{$align-opp}: 11px; border-radius: calc($border-radius-large * 0.5); }
     }
  } 
  &-sub-title { position: absolute; z-index: 1; top:auto; bottom: -1rem; #{$align-def}:2rem; #{$align-opp}:auto; background-color: $cfd-blue; color: $white; font-size: 1rem; padding: .625rem 1rem; 
    border-radius: $border-radius-large * 2; box-shadow: none; 
    @include media-breakpoint-up(lg) { top:-1.5rem; bottom: auto; #{$align-opp}:-4.5rem; #{$align-def}:auto; font-size: 1.125rem; padding: .85rem 1.5rem; box-shadow: 0 44.6489px 57.923px rgba(24, 74, 181, 0.34); } 
    strong { font-weight: $font-weight-regular; } 
  }
  &-items { position: relative; z-index: 1; text-align: center; padding-top: 1.5rem; padding-bottom: 2rem; 
    @include media-breakpoint-up(md) { padding-top: 4.5rem; padding-bottom: 0; } 
  }   
  &-item { font-size: 1.375rem; font-weight: $font-weight-light; color:$dark-blue; line-height: 1.1; padding: 1rem;
    strong { font-weight: $font-weight-regular; }
    span { font-size: 1.725rem; font-size:clamp(1.125rem, 4.5vw, 3.125rem); display:block; } 
    @include media-breakpoint-up(md){ font-size:1.875rem;
         span { font-size:3.125rem; font-size:clamp(1.725rem, 5vw, 3.125rem); }
     } 

  }
  .item-anim-list { position: relative; overflow: hidden; min-height: 6rem;
    @include media-breakpoint-up(md) { min-height: 11rem; } 
   .item-anim, .item-anim-second { position: absolute; left: 0; right:0; opacity: 0; 
      &:first-child { opacity: 1; }
   }
  }
  .disclaimer-note { font-size: 1.125rem; color: $color-main; margin-top: 3rem; text-align: center; }
}   

//Payment    
.payment { 
  &-section { position:relative; 
    .plus-bg { 
        &-large { top:25%; #{$align-opp}:-10rem; width:25rem; height:25rem; }
      }
  }   
  &-header { position:relative; z-index:0; padding-right:0; padding-left:0; 
    &-bg { display:flex; justify-content:center; align-items:flex-end; min-height:22.5rem; margin-bottom:3rem; background-image:url("../Images/newhome/img/iPhone-transperent.webp"); background-position:50% 100%; background-repeat:no-repeat; background-size:auto 100%;
        #{$no-webp} { background-image: url("../Images/newhome/img/iPhone-transperent.png"); } 
        @include media-breakpoint-up(md){ min-height:31.25rem; margin-bottom:2rem; } 
        &:after { position:absolute; bottom:0; left:0; right:0; z-index:-1; margin:0 auto; width:100%; height:25%; background:$section-white-gradient; content:""; 
            @include media-breakpoint-up(md){ width:70%; }
        }
    }
    img, svg { width:17.25rem; height:auto; margin:0 auto; display:block; 
        @include media-breakpoint-up(md){ width:20rem; } 
    } 
    .plus-bg {
        &-small { top:12%; #{$align-opp}:-2.5rem; width:5.625rem; height:5.625rem; 
            @include media-breakpoint-up(md){ top:35%; #{$align-opp}:20%; }
        }
        &-medium { top:-4rem; #{$align-def}:3%; width:4rem; height:4rem; 
            @include media-breakpoint-up(md){ top:0; #{$align-def}:-8%; width:10.5rem; height:10.5rem; }
        }
        &-xlarge { 
            @include media-breakpoint-up(md){ top:25%; #{$align-def}:10%; width:34rem; height:34rem; } 
        }
    }  
  } 
  &-items { position:relative; z-index:1; 
    .plus-bg { display:none;
        @include media-breakpoint-up(medium){ display:block; } 
        &-medium { top:80%; #{$align-def}:-8%; width:15.375rem; height:15.375rem; }
        &-large { top:-10rem; #{$align-opp}:-10%; width:29.5rem; height:29.5rem; }
    }  
  }
  &-brands { margin-top:4.25rem; margin-bottom:1.5rem;
    @include media-breakpoint-up(md){ margin-top:12.5rem; margin-bottom:2.5rem;
        .regulator-cysec.country-es & { margin-top:0; }
    }
    .main-column {
        .regulator-cysec.country-es & { @include media-breakpoint-up(lg){ @include make-col(7); } } // special rule for Spain regulation
    }
  }
  .disclaimer-zone { color:$color-main; text-align:center; font-size:.9375rem; }
} 
 
//Insights
.main-banner + .insights,
.main-banner + .pin-spacer > .insights { margin-top: 2.5rem;
    @include media-breakpoint-up(lg) { margin-top: 8.5rem; }
 }
.insights { position: relative;
  .plus-bg {
      &-small { width: 6.25rem; height: 6.25rem; top:80%; #{$align-def}:auto; #{$align-opp}:-3rem; 
          @include media-breakpoint-up(lg) { width: 11.25rem; height: 11.25rem; top:30%; #{$align-def}:2%; #{$align-opp}:auto; }
      }
      &-medium { width: 15rem; height: 15rem; top:-20%; #{$align-opp}:-10%;
          @include media-breakpoint-up(medium down) { display: none; }
      }
      &-large { width: 17.5rem; height: 17.5rem; top:60%; #{$align-opp}:auto; #{$align-def}:4%;
          @include media-breakpoint-up(lg) { #{$align-opp}:-10%; #{$align-def}:auto; width: 24rem; height: 24rem; top:40%; }
      }
    }
  .title-section { margin-bottom: .5rem; line-height: 1.1;
      strong { text-transform: none; margin-top: 0; display: flex; align-items: baseline; 
          @include media-breakpoint-only(sm) { align-items: center; flex-direction: column; }
      }
      span { direction: ltr; white-space: nowrap;
          @include media-breakpoint-up(md) { margin-#{$align-def}: 1rem; }
          &:before{ content:''; height: 2rem; width: 2.5rem; display: inline-flex; margin-#{$align-opp}: .25rem;
              background-image: url("../Images/newhome/svg/icon-insights.svg"); background-position: 50% 50%; background-repeat: no-repeat;
              @include media-breakpoint-up(md) { height: 3.25rem; width: 5rem; }
          }
       }
     }
  .blue-card-parent { padding-top: 5rem;
      @include media-breakpoint-only(sm) { padding-top: 2rem; }
  }
  .blue-card-bg { height: 85%;
        @include media-breakpoint-down(lg) { height: 100%; } 
        &:after { background-size: auto 100%; background-position: 50% 0; background-repeat: no-repeat;
            @include media-breakpoint-up(md) { #{"/*!rtl:ignore*/"}background-position: 100% 0, 50%; background-size: auto 100%; }
        }
        /*rtl:raw: &:after { transform: rotateY(10deg) scaleX(-1); }*/
        &.lazyloaded {
            &:after {
                background-image: url("../Images/newhome/img/insights-bg-new.webp"), radial-gradient(44% 158% at 37% 90%,$dark-blue 0,$dark-blue 100%);
                 #{$no-webp} { background-image: url("../Images/newhome/img/insights-bg-new.png"), radial-gradient(44% 158% at 37% 90%,$dark-blue 0,$dark-blue 100%); } 
            }
        }
   }
  .blue-card-content { padding-top: 2rem; padding-bottom: 3rem;
      @include media-breakpoint-up(md) { padding-#{$align-def}: 2.5rem; padding-#{$align-opp}: 0; }
      @include media-breakpoint-up(lg) { padding-top: 3rem; padding-#{$align-def}: 3.5rem; }
      @include media-breakpoint-up(xl) { padding-top: 0;}    
      @include media-breakpoint-up(xxl) { padding-#{$align-def}: 7.5rem; }
   }
   &-list-container {
      @include media-breakpoint-down(lg) { position: relative; }
   }
    &-list-data{ display: flex; flex-direction: column; align-items: center; color: $white; font-size: 1.125rem; 
      @include media-breakpoint-up(md) { font-size: 1.5rem; }
      @include media-breakpoint-up(lg) { font-size: 1.75rem; }
          ul{ font-weight: $font-weight-light; list-style: none;  margin:0 0 1rem; text-align:#{$align-def}; 
              @include media-breakpoint-up(md) { margin:0 .5rem 1rem; }
          }
          li { position: relative; padding: .5rem; padding-#{$align-def}: 2.5rem; padding-#{$align-opp}: 0; backface-visibility: hidden; line-height: 1.2; 
              &:before { content: ''; position: absolute; top:50%; #{$align-def}:0; width:1.375rem; height: 1.375rem; margin-top: -.6875rem; 
              background-image: url("../Images/newhome/svg/plus-white-fill.svg"); background-repeat: no-repeat; background-size: 100% auto; 
                 @include media-breakpoint-up(md) { width:2rem; height: 2.2rem; margin-top: -1.1rem; }
              }
              @include media-breakpoint-up(md) { padding: .5625rem; padding-#{$align-def}: 4rem; }
              a { text-decoration: underline; 
                &:hover, &:focus { text-decoration: none; }
              }
          }
     }
     &-content { font-weight: $font-weight-light; line-height: $line-height-small; 
      a { color:$white; text-decoration: underline;
        &:hover, &:focus { color:$turquoise }
       }
   }
    &-link { color:$white; text-decoration: underline; font-size: 1.125rem; margin-top: 1rem;
        @include media-breakpoint-up(md) { font-size: 1.5rem; }
        &:hover, &:focus { color:$turquoise }
    }
    &-img-box { position: relative; margin-top: 5rem; min-height: 30rem;
        @include media-breakpoint-up(xl) { margin-top: -35%; min-height: 50rem; }
    }
    &-img{position: absolute; top:0; left:0; right:0; width:23.5rem; height: auto; max-width: 13.75rem; display: block; margin: 0 auto 3.5rem; box-shadow: 0 45px 58px rgba(24, 74, 181, 0.34); border-radius: 2.5rem;
         @include media-breakpoint-up(xl) { max-width: 100%; margin-bottom: 0; border-radius:4.5rem; }
    }
}

//Trading
.trading {            
  &-section { position: relative; padding-top: 5rem; color: $white; text-align: center; 
    @include media-breakpoint-only(sm) { 
      &:before, &:after { content: ""; position: absolute; width: 100%; z-index: -1; display: block; border-radius: $border-radius-large;
          border-top-#{$align-opp}-radius: 0; border-bottom-#{$align-opp}-radius: 0; transform: $card-skew;
        }
      &:before { padding: 6px; border: 6px solid $dark-blue; border-#{$align-opp}: none; top: 0; left: 0; right: 0; bottom: 0;
          background-image: linear-gradient(180deg, $turquoise 0%, $dark-blue 20%);
        }
      &:after { top: 7px; bottom: 7px; #{$align-def}: 7px; #{$align-opp}: 0; border-radius: $border-radius-medium;
          background: $card-gradient; border-top-#{$align-opp}-radius: 0; border-bottom-#{$align-opp}-radius: 0;
        }  
     } 
    @include media-breakpoint-up(md) { padding-top: 8.25rem; background: linear-gradient(180deg, $dark-blue 60%, $white 96%); }
    .plus-bg { z-index: 0;
      &-small { width: 4.75rem; height: 4.75rem; top:5%; #{$align-opp}:20%; }
      &-medium { width: 10rem; height: 10rem; top:32%; #{$align-opp}:-5%; }
      &-large { width: 17.5rem; height: 17.5rem; top:15%; #{$align-def}:-8%; }
      @include media-breakpoint-down(lg) { 
          &-medium, &-large { display: none; }
       }
      @include media-breakpoint-only(sm) { transform: none;
          &-small, &-medium { display: none; }
          &-large { display: block; width: 5.25rem; height: 5.25rem; top:80%; #{$align-def}: 8%; }
       }
    } 
  }   
  &-img { #{"/*! rtl:ignore */"}direction: ltr; position: relative; display: flex; justify-content: flex-end; padding-top: 0; padding-bottom: 4.5rem; bottom: -6rem; margin-bottom: 6rem;
    @include media-breakpoint-up(md) { padding-top: 4.5rem; bottom: 0; margin-bottom: 0; } 
    .plus-bg { z-index: 2;
      &-small { top:84%;  #{"/*! rtl:ignore */"}right:16%;  #{"/*! rtl:ignore */"}left:auto; }
      &-medium { top:40%;  #{"/*! rtl:ignore */"}left:20%;  #{"/*! rtl:ignore */"}right:auto; }
      @include media-breakpoint-only(sm) {
          &-small, &-medium { display: block; }
          &-small { width: 4rem; height: 4rem;  #{"/*! rtl:ignore */"}left:6%;  #{"/*! rtl:ignore */"}right:auto; }
          &-medium { width: 6rem; height: 6rem; top:60%;  #{"/*! rtl:ignore */"}left:auto;  #{"/*! rtl:ignore */"}right:6%; }
       }
    }
    &-box { position: relative; max-width: 100%; width:auto; display: block; 
        @include media-breakpoint-only(sm) { overflow: hidden; max-height: 20rem; }  
        @include media-breakpoint-up(md) { width: 64%; margin-right: -4%; min-height:38rem; }
        &:before { content: ''; position: absolute; bottom: 0; width: 100%; height: 75%; z-index: 1; 
              background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); 
             @include media-breakpoint-up(md) { bottom: -4rem; }
        }
        img { max-width: none;
            @include media-breakpoint-only(sm) { transform: scale(.75); transform-origin: 0 0; margin-right: 4%; }
         }
      }  
  }
}

//Products
.product-section { padding-top:$spacer * 5;
    &.no-ad-style { padding-top:0; }
    @include media-breakpoint-up(lg) { padding-top:0; }
}