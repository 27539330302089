// Slick Slider
$light-grey-dots:#adadad; // use color variables only within this partial
$light-blue-dots:#2e86fe; // use color variables only within this partial
$light-blue-arrow:#2e86fe; // use color variables only within this partial
$dark-grey-arrow:#555555; // use color variables only within this partial
.slick {
	&-slider { position:relative; display:block; box-sizing:border-box; user-select:none; touch-action:pan-y; -webkit-tap-highlight-color:transparent; }
	&-list { position:relative; overflow:hidden; display:block; margin:0; padding:0;
		&:focus { outline:none; }
		&.dragging { cursor:pointer; }
	}
	&-slider .slick-track,
	&-slider .slick-list { transform:translate3d(0, 0, 0); }
	&-track { position:relative; left:0; top:0; display:block; margin-left:auto; margin-right:auto;
		&:before, &:after { content:""; display:table; }
		&:after { clear:both; }
		.slick-loading & { visibility:hidden; }
	}
	&-slide { display:none; float:left; height:100%; min-height:1px;
		img { display:block; }
		&.slick-loading img { display:none; }
		&.dragging img { pointer-events:none; }
		.slick-initialized & { display:block; }
		.slick-loading & { visibility:hidden; }
		.slick-vertical & { display:block; height:auto; border:1px solid transparent; }
	}
	&-arrow {
		&.slick-prev, &.slick-next { font-size:0; height:2.25rem; width:2.25rem; position:absolute; top:50%; transform:translate(0, -50%); background:transparent; border:none; 
			&:before { color:$light-blue-arrow; font-family:'icomain'; font-size:1.25rem; content:$icon-chevron-left; 
				[dir="rtl"] & { content:$icon-chevron-right; }
			}
		}
		&.slick-next { #{$align-opp}:-2rem;
			&:before { content:$icon-chevron-right;
				[dir="rtl"] & { content:$icon-chevron-left; }
			}
		}
		&.slick-prev { #{$align-def}:-2rem; }
		&.slick-disabled { cursor: not-allowed;
			&:before { color:$dark-grey-arrow; }
		}
		&.slick-hidden { display:none; }
	}
	
	&-dots { position:absolute; bottom:0; display:block; left:0; width:100%; height:2.25rem; padding:0; margin:0; list-style:none; text-align:center;
		li { position:relative; width:1rem; height:1rem; margin:0 .5rem; padding:0; display:inline-block; cursor:pointer;
			button { background-color:$light-grey-dots; height:.875rem; width:.875rem; border:none; border-radius:50%; cursor:pointer; display:block; font-size:0; overflow:hidden; position:relative;
				&:hover, &:focus { outline:none;
					&:before { opacity:1; }
				}
				&:before { opacity:.25; width:100%; height:100%; content:""; position:absolute; top:0; left:0; }
			}
			&.slick-active button:before { opacity:.75; background-color:$light-blue-dots; }
		}
	}
}  