﻿.payment {
	&-section {
		.disclaimer-zone { position:relative; color:$color-main; text-align:center; font-size:.75rem; 
			@include media-breakpoint-up(md){ font-size:1rem;  }
		} 
	}
	&-brands { margin-top:$spacer * 1.5; margin-bottom:$spacer * 1.5;
		@include media-breakpoint-up(md){ margin-top:$spacer * 2.5; margin-bottom:$spacer * 2.5; }
	}
	&-brand { flex:0 0 auto; width:auto; 
		@include media-breakpoint-up(md){ margin:0 2%; }
		&-list { display:flex; justify-content:center; align-items:center; }
		&-img { position:relative; margin-bottom:0;
			img, svg { display:block; margin:($spacer * .5) auto; width:auto; height:1.75rem;
				@include media-breakpoint-up(md){ height:3.5rem; }
			}
		}
	}
}
 