﻿/// <reference path="../../../../node_modules/bootstrap/scss/bootstrap.scss" />
/// <reference path="../_variables.scss" />

//@import "../../../ui-helpers/cookie-banner-sticky";
//@import "../../../ui-helpers/cookie-banner-modal";
//@import "../../../ui-helpers/scrolled-app-popup";
@import "../../shared/support-link";

$bg-footer: #07183d;
.support-section { background-color:$medium-blue; padding:2rem 0;
    .title { color:$white; font-weight:$font-weight-light; margin:0 0 1rem 0; text-align:center;
        @include media-breakpoint-up(md) { font-size:2rem; margin:0 1rem; }
    }     
    .support-link { border:1px solid $white; border-radius:.5rem; font-size:.875rem; justify-content:center; line-height:$line-height-small; padding:.875rem 1rem; transition:background-color .25s ease-out, color .25s ease-out;  
        @include media-breakpoint-up(md) { font-size:1rem; max-width:11rem; }
        .icon-users { margin-left:.5rem; }
        &:hover, &:focus { background:$white; color:$medium-blue; }
    }
 }
.main-footer { background-color: $bg-footer; color: $white; padding: 5rem 0;
    @include media-breakpoint-up(lg) { padding: 7rem 0; }
    .row { max-width: 90rem; }
    p, span { font-weight: $font-weight-regular; font-size: .875rem;
        @include media-breakpoint-up(md) { font-size: 1rem; }
    }
    // TODO: style the links menu 
    .links-menu { margin-bottom:3rem; padding:0;
        @include media-breakpoint-up(lg) { margin-bottom: 0; }
        li { align-items: flex-start; display: flex; padding-#{$align-def}: 1.5rem; padding-#{$align-opp}: 1rem; position: relative; margin-bottom: 2.25rem; flex: 1 0 100%;
            @include media-breakpoint-up(md) { flex: 0 1 50% }
            @include media-breakpoint-up(lg) { margin-bottom: 1rem; flex: 1 0 100%; padding-#{$align-opp}: 0; }
        }        
        a { color: $white; padding: 0; line-height: 1.3; }
    }    
    .main-column { margin-bottom: 2rem; font-size: .875rem;
        @include media-breakpoint-up(lg) { font-size: 1rem; margin-bottom: 0; padding: 0 1.5rem; }
        @include media-breakpoint-up(xl) { padding: 0 2rem; }
    }
    .social-networks { text-align:center;
		@include media-breakpoint-up(lg) { text-align:left; }
	}
    .social-menu { display:flex; list-style:none; padding:0; margin:$spacer 0 0; font-weight:$font-weight-regular; justify-content:center;
		@include media-breakpoint-up(lg) { justify-content:flex-start; }
		li { margin-right:$spacer * 1.25;
			@include media-breakpoint-up(lg) { margin-right:$spacer; }
			&:last-child { margin-right:0; }
			a { padding:0; display:block; line-height:1; font-size:.9375rem;
                .country-id & { pointer-events:none;
                    &:focus-visible { box-shadow:none!important; }
                }
            }
		}
		img { width:1.875em; height:auto; }
		.social-icon {
            &-linkedin { width:2em; }
            &-youtube { width:2.25em; }
        }
	}
    .sg-awareness-link {
        &:active, &:hover { text-decoration:underline; }
    }
}

.footer {
    &-item {
        &.secured { align-items: center; display: flex; justify-content: flex-start; margin-top: 3rem; text-align: #{$align-def};
            img { margin-#{$align-opp}: .5rem; }
            p { margin-bottom: 0; line-height: 1.2; }
        }
    }
    &-text { line-height: $line-height-small; font-size: .875rem;
        @include media-breakpoint-up(md) { font-size: 1rem; }
        a { color: $white; }
    }
    &-contact { font-size:1.125rem; line-height:$line-height-base;
        @include media-breakpoint-up(md){ text-align:center; font-size:1.25rem; }
        a { color:$white; text-decoration:underline;
            &:hover, &:focus { text-decoration:none; }
        }
     }
}

.portuguese-risk-warning { margin-bottom: 1rem;
    .warning-content { border:3px solid $white; padding:($spacer * .5) ($spacer * .75);
        @include media-breakpoint-up(lg) { border-width: 4px; }
    }
    p, span { color: $white; font-weight: $font-weight-bold; font-size:.75rem; margin:0; }
    strong { color: $white; font-weight: $font-weight-extra-bold; text-align:center; }
    img { display: block; margin: 0 auto; }
}

//// END FOOTER ////

.sticky-footer-wrapper { position: fixed; bottom: 0; width: 100%; z-index:$zindex-sticky;
    @supports (position: sticky) { position: sticky;
        .edge & { position: fixed; } // Edge 16-18 has issues with position sticky although it's supported 
    }
    @media (hover: none) and (pointer: coarse) {
        position: fixed; // set position fixed for touch devices, prevent address bar height calculation bug
    }
    .main-row { max-width: 90rem; }
    // Risk warning
    .rw {
        &-container { background-color:$bg-secondary-light; padding: .25rem 0; border-top: 1px solid #ccc; position: relative; z-index: 2;
            p { color: $text-color; font-size: .75rem; margin-bottom: 0; font-weight: $font-weight-regular; letter-spacing: 0; line-height: 1.15;
                @include media-breakpoint-up(md) { font-size: .9375rem; }
            }
            &.blocked { background-color:$rw-background-color-secondary;
                p { color:$rw-text-color-secondary;
                    * { color:$rw-text-color-secondary; }
                }
            }
        }
        &-toggle { display:none; }
        &-short { @include media-breakpoint-up(md) { display:none; } }
        &-long { display:none;
            @include media-breakpoint-up(md) { display:block; }
        }
    }
    
}