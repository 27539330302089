﻿//Products
.product {
  &-section { position: relative; 
    .plus-bg {
        &-small { width: 2.5rem; height: 2.5rem; }
        &-medium { width: 4.5rem; height: 4.5rem; }
        &-large { width: 6.5rem; height: 6.5rem; }
        @include media-breakpoint-up(md) { 
          &-small { width: 9.5rem; height: 9.5rem; }
          &-medium { width: 12rem; height: 12rem; }
          &-large { width: 21.5rem; height: 21.5rem; }
        }
      } 
  }
  &-card {
    @include media-breakpoint-only(sm) { padding-left: 0; padding-right: 0; order:1; margin-bottom: 5rem;
      &-cfd { order: 0; }
      &-invest { 
        .blue-card-bg { transform: scaleX(-1) $card-skew; } 
      }
    }
    @include media-breakpoint-up(md) { 
        &:nth-child(2n+1) { margin-top: 3.34%; }   
        &:nth-child(2n) { margin-bottom: 3.34%; }
        &:last-child:nth-child(2n+1) { margin-top: 0; }
        
        // Only Edge 16
        @supports (-ms-ime-align:auto) and (not (-webkit-line-clamp: 1)) {
          &:nth-child(2n+1) { margin-top: 3.25rem; }   
          &:nth-child(2n) { margin-bottom: 3.25rem; }
        }
     }     
    &-invest {
       .plus-bg {
          &-small { top:10%; #{$align-def}:5%; z-index: 0; }
          &-medium { top:94%; #{$align-def}:6%; 
              &.white { z-index: 0; top:12%; #{$align-opp}:-12%; #{$align-def}:auto; }
          }
          &-large { top:-15%; #{$align-def}:24%; }
          @include media-breakpoint-up(md) { 
              &-small { top:78%; width: 4.75rem; height: 4.75rem; }
              &-large { top:-24%; #{$align-def}:-24%; }
          }
        } 
    }
    &-cfd {
        .plus-bg {
          &-small { top:90%; #{$align-opp}:6%;
              &.white { z-index: 0; }
          }
          &-large { top:-15%; #{$align-def}:12%; }
          @include media-breakpoint-up(md) {
              &-small { top:-25%; #{$align-opp}:0; }
           }
        } 
    }
    &-futures {
        .plus-bg {
          &-small { top:90%; 
              &.white { z-index: 0; top:7%; #{$align-opp}:4%; #{$align-def}:auto; }
               @include media-breakpoint-only(sm) { 
                  &.blue-gr { width: 4.5rem; height: 4.5rem; #{$align-def}:40%; }
               }
          }
          &-medium { display: none; }
          @include media-breakpoint-up(md) { 
              &-small { #{$align-opp}:-35%; }
              &-medium { top:20%; #{$align-def}:-30%; display: block; }
          }
        }
    } 
  } 
  &-item { padding: 5rem 2rem;  display: flex; justify-content: center;
    @include media-breakpoint-up(md) { min-height: 23rem; padding:3rem 2rem; height: 100%; }
    .blue-card-bg { min-height: auto; 
        @include media-breakpoint-up(md) { transform: skew(0deg,0deg); } 
        @include media-breakpoint-only(sm) { 
          &:before, &:after { border-top-#{$align-opp}-radius: 0; border-bottom-#{$align-opp}-radius: 0; }
          &:before { border-#{$align-opp}: none; }
          &:after { #{$align-def}: 7px; #{$align-opp}: 0; border-top-#{$align-opp}-radius: 0; border-bottom-#{$align-opp}-radius: 0; }
        }  
    }
    &-data { display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center; letter-spacing: $letter-spacing; }
  }
  &-brand {  
     img, svg { height: 2.875rem; width: auto; } 
  }
  &-desk { font-size: 1.25rem; padding-top: .75rem; line-height: $line-height-small; } 
  &-note { font-size: .875rem; padding-top: .75rem; }
  &-note + &-note { padding-top: 0; }
  &-available-note { padding-top: 2rem; font-size: 1.125rem; font-weight: $font-weight-light; } 
  &-button {
      @include media-breakpoint-only(sm) { margin-top: 2rem; }
   }
}

// Warning: Various hacks and shit code ahead - this was a rush job
.no-ad-style { position:static; isolation:isolate; margin-bottom:4.5rem;
    @include media-breakpoint-up(md){ margin-bottom:7.5rem; }
    .plus-bg { display:none; }
    .product {
        &-card { margin:0 0 ($spacer * 2) 0; padding-left:calc(var(--bs-gutter-x)*.5); padding-right:calc(var(--bs-gutter-x)*.5);
            &-invest { order:2;
                .product-brand { background-image:url(../Images/newhome/svg/plus500-invest.svg#blue); }
            }
            &-cfd { order:1;
                .product-brand { background-image:url(../Images/newhome/svg/plus500-cfd.svg#blue); }
            }
            &-futures { order:3;
                .product-brand { background-image:url(../Images/newhome/svg/plus500-futures.svg#blue); }
            } 
            &-link {
                a {
                    &:hover, &:focus {
                        .product-brand {
                            &.invest { background-image:url(../Images/newhome/svg/plus500-invest.svg); }
                            &.cfd { background-image:url(../Images/newhome/svg/plus500-cfd.svg); }
                        }
                         .blue-card-bg {
                            &:before { border-color:$dark-blue; background-image:linear-gradient(to left, $white, $medium-blue); }
                            &:after { background:$dark-blue; }
                        }
                        .product {
                            &-desk, &-note { color:$white; }
                        }
                    }
                }
                &.market-news, &.academy { --bs-heading-color:#{$white};
                    .blue-card-bg {
                        &:before { border-color:$dark-blue; background-image:linear-gradient(to left, $light-blue, $dark-blue); }
                        &:after { background-color:$dark-blue; background-image:radial-gradient(50% 80% at 25% 50%, $gr-blue 0%, $dark-blue 100%); }
                    }
                    .product-desk { color:$white; }
                     a {
                        &:hover, &:focus { --bs-heading-color:#{$dark-blue};
                             .blue-card-bg {
                                &:before { border-color:$white; background-image:linear-gradient(to left, $medium-blue, $white); }
                                &:after { background:$white; }
                            }
                            .product-desk { color:$dark-blue; }
                        }
                    }
                }
                .background-element { background-repeat:no-repeat; border-radius:1.5rem; opacity:.2; position:absolute; width:100%; height:100%; z-index:2; }
                &.market-news { order:4;
                    .background-element { background-image:url(../Images/svg/card-background-market-news.svg); background-position:left -1rem bottom -3rem; background-size:10em;
                        @include media-breakpoint-up(xxl) { background-position:left -2rem bottom; background-size:auto; }
                    }
                }
                &.academy { order:5;
                    .background-element { background-image:url(../Images/svg/card-background-academy.svg); background-position:left -3rem bottom -2.5rem; background-size:12em;
                        @include media-breakpoint-up(xxl) { background-position:left -8rem bottom -2rem; background-size:auto; }
                    }
                }
            }
        }
        &-item { display:block; min-height:16.5rem; height:100%; padding:1.5rem; color:$dark-blue; 
            .blue-card-bg, *:before, *:after { transform:none !important; }
            *:before  { border-radius:1.5rem;  border-color:$white; border-right:6px solid $white; box-shadow:0 2.125rem 4.25rem 0 rgba(0,0,0,.13); background-image:linear-gradient(to left, $medium-blue, $white); }
            *:after  { border-radius:1.125rem; right:7px; background:$white; }
            &-data { height:100%; }
        }
        &-brand { width:100%; height:2.75rem; margin-bottom:.5rem; background-repeat:no-repeat; background-position:50% 50%; background-size:contain;
            img { display:none; }
        }
        &-desk { color:$dark-blue; font-size:1.125rem; padding:.5em 0; }
        &-available-note { font-size:.75rem; padding:.5em 0; }
    }
    .col-lg-x { @include media-breakpoint-up(lg) { width:33.3333%; } }
}