﻿// Variables

// TODO: cleanup duplicated variables

// TEMP - FOR COMPATIBILITY
$align-def: left;   //$default-align
$align-opp: right;  //$opposite-align
$dir-def: ltr;      //$default-direction
$dir-opp: rtl;      //$opposite-direction

// if RTL
/*@if $RTL { 
  $align-def: right;
  $align-opp: left;
  $dir-def: rtl;      //$default-direction
  $dir-opp: ltr;      //$opposite-direction
}*/


// old _base
$color-text: #5e5e5e; //#595959 //#707070
$color-text-darker: #4d4d4d;
$color-text-light: scale-color($color-text, $lightness: 15%);
$color-text-dark: scale-color($color-text, $lightness: -7%); //#575757
$color-light: #82b5d9;
$color-logo-text:#223a74;

$body-color: #555555;
$text-color: $body-color;
$color-main: $body-color;
$color-main-hover: #006db5; //FYI: for hover: nav, bg, search-results


$color-blue: #004080;
$color-blue-dark: #02407D;
$color-blue-light: #E7F3FF;
$color-blue-grey: #84A1BF;
$color-green-light: #80D04F;
$color-turquoise: #50A4F9;
$color-turquoise-light: #4FC0FF;
$color-grey: #E9EAEB;
$color-white: #FFFFFF;
$color-svg-blue-dark: #114982;
$color-svg-blue-light: #376AA0;
$color-svg-blue-grey: #527BA5;
$bg-blue-radial-gradient: radial-gradient(ellipse at center, rgba(255,255,255,0.15) 0%,rgba(255,255,255,0) 70%), $color-blue;
$box-shadow: 0 8px 16px rgba(0, 0, 0, .2);


$red: #cc2929;
//$green: #0f993d; //Old (BG and Text)
$green: #0D8234;// Good Contrast for Accessibility 'AA' // good for white BG (not gray): #0E8736
$white: #fff;
$black: #000;
$green-hover: scale-color($green, $lightness: 10%);
$green-bg: #0E8a37;// Good Contrast for Accessibility 'AA' // good for white BG (not gray): #0E8736
$green-islamic: #167f0c;
$red-sg: #b5201a;
$yellow: #ffd400;
$yellow-dark: scale-color($yellow, $lightness: -12%); //#e0bb00; ////on design:#e6b045
$color-down: $red; //red
$color-up-bg: $green-bg; //green BG
$color-off: #cccccc; //gray
$color-facebook: #3b5998;
$color-twitter: #0f1419;
$color-whatsapp: #4fce5d;
$color-linkedin: #0e76a8;
//$color-google: #dd4b39;
$color-instagram: #bc32a4; //purple - color picked from instagram website
$color-gold: #d19b43;
$color-brown: #a28454;
$color-light-brown: #b08f5a;
$color-dark-brown: #402a0d;
$bg-main: $white;
$bg-main-new: #004487;
$bg-secondary: #f6f6f6;
$bg-secondary-light: scale-color($bg-secondary, $lightness: -4%); //#ececec
$bg-secondary-dark: scale-color($bg-secondary, $lightness: -15%); //#d1d1d1
$bg-colored: #0063a6;
$bg-colored-light: scale-color($bg-colored, $lightness: 80%);
$bg-colored-dark: #09192b;
$bg-colored-overlay-color: rgba(0, 64, 128, 0.8);  
$bulls-bg-colored-overlay-color: rgba(12, 39, 128, .2);
$bg-button: #004080;
//$bg-side: #369990;
$bg-side: #2B827B;// Good Contrast for Accessibility 'AA'2B827B
$bg-table-hover: #e3ebfc;
$bg-arrow-color: scale-color($color-light-brown, $lightness:25%); //FYI: background arrows in professional account page
$color-support: $bg-side;   //need to be after the var call
$border-main: #cccccc;
$border-secondary: $bg-secondary;
$border-colored: $bg-colored;
$border-colored-light: #adc3d9;
$border-light: $white;
//$radius:4px;   //border-radius value
$radius: .5rem;   //border-radius value
$radius-double: 1rem;
$radius-button: 3rem;
$radius-input: 2rem;
//$radius-button: 8px;
$no-radius:0;   //border-radius value
$line-height-main: 1.5;   //recommended for accessibility
$line-height-def: 1.3;   //default
$line-height-tight: 1.2;
$font-light: 300;
$font-med: 400;
$font-bold: 500;
$font-bolder: 700;
$icon-transition: color 0.25s ease-in;
$icon-avg-transition: fill 0.25s ease-in;
$icon-search: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3MCAzMiI+PHBhdGggZD0iTTMyLjQsNC42YTguNzgsOC43OCwwLDAsMSw3Ljg4LDQuODksOC43OSw4Ljc5LDAsMCwxLS44Nyw5LjIzbDYuNjQsNi42M0ExLjIsMS4yLDAsMCwxLDQ2LjExLDI3YTEuMjEsMS4yMSwwLDAsMS0xLjYyLjE5bC0uMTQtLjEyLTYuNjMtNi42NEE4Ljc5LDguNzksMCwwLDEsMjUuMjYsOC4yNyw4Ljc5LDguNzksMCwwLDEsMzIuNCw0LjZabTAsMi40YTYuNCw2LjQsMCwxLDAsNC41MywxLjg4QTYuMzksNi4zOSwwLDAsMCwzMi40LDdaIiBmaWxsPSIjYWVhZWFlIi8+PC9zdmc+");
$icon-search-active: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3MCAzMiI+PHBhdGggZD0iTTMyLjQsNC42YTguNzgsOC43OCwwLDAsMSw3Ljg4LDQuODksOC43OSw4Ljc5LDAsMCwxLS44Nyw5LjIzbDYuNjQsNi42M0ExLjIsMS4yLDAsMCwxLDQ2LjExLDI3YTEuMjEsMS4yMSwwLDAsMS0xLjYyLjE5bC0uMTQtLjEyLTYuNjMtNi42NEE4Ljc5LDguNzksMCwwLDEsMjUuMjYsOC4yNyw4Ljc5LDguNzksMCwwLDEsMzIuNCw0LjZabTAsMi40YTYuNCw2LjQsMCwxLDAsNC41MywxLjg4QTYuMzksNi4zOSwwLDAsMCwzMi40LDdaIiBmaWxsPSIjMDA0ZDk5Ii8+PC9zdmc+");
$width-transition: width 0.3s ease-in;
$border-color-transition: border-color 0.25s ease-in;

//$darker-gray: scale-color($dark-gray, $lightness: -35%); // #595959 = #5a5a5a;
//$darker-deep-gray: scale-color($dark-gray, $lightness: -55%); //#3d3d3d
$gray-background: #f1f1f1;
$gray-background-light: #fefefe;
$light-grey: #adadad;
$background-hover: #e3ebfc;

$svg-chevron-down: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 32 32' fill='#{$white}'><path d='M32 11l-2-2-14 15-14-14-2 2 16 16 16-16z'/></svg>") !default;
$svg-chevron-down-black: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 32 32' fill='#{$black}'><path d='M32 11l-2-2-14 15-14-14-2 2 16 16 16-16z'/></svg>") !default;
$svg-chevron-up: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 32 32' fill='#{$white}'><path d='M3.7625231158332975,20.728265705638506 L15.84661770788584,8.64417111358596 L27.930712299938385,20.728265705638506 L29.65701152737446,19.001966478202426 L15.84661770788584,5.191572658713806 L2.0362238883972164,19.001966478202426 L3.7625231158332975,20.728265705638506 z'/></svg>") !default;
$svg-chevron-up-black: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 32 32' fill='#{$black}'><path d='M3.7625231158332975,20.728265705638506 L15.84661770788584,8.64417111358596 L27.930712299938385,20.728265705638506 L29.65701152737446,19.001966478202426 L15.84661770788584,5.191572658713806 L2.0362238883972164,19.001966478202426 L3.7625231158332975,20.728265705638506 z'/></svg>") !default;
// TODO: pass color to svg to save these 2 lines

//$space-half: .5rem;   //TODO: organize margin and padding with 'space' vars
//$space-full: 1rem;
//$space-double: 2rem;
//  // 'show-for-sr' class is for 'screen-readers' for Accessibility


// new _base
$color-main: #555555;
$color-main-hover: #006db5; //FYI: for hover: nav, bg, search-results
$color-main-light: scale-color($color-main, $lightness: 30%);
$color-main-light-hover: scale-color($color-main, $lightness: 45%);
$color-main-lighter: scale-color($color-main, $lightness: 70%);
$color-text-dark-blue: #0c2780;
$color-text-light-grey: #555555;
$color-text-light-grey-accessible: #767676;
$turquoise: #07cee0;
$gr-blue: #223f9f;
$color-dismiss-bg: $light-grey;
$color-faq-btn: $color-text-dark-blue;

$cyan: #63f0eb;
$light-blue: #11afff;
$medium-blue: #2e86fe;
$medium-dark-blue: #136DE8;
$dark-blue: #0c2780;
$cfd-blue: #184ab5;
$invest-blue: #11afff;
$color-sub-brand-invest: $invest-blue;
$color-sub-brand-cfd: $dark-blue;
$color-sub-brand-invest-hover: $light-blue;
$color-sub-brand-cfd-hover: #324c8b;
$color-sub-brand-invest-disabled: rgba(32, 149, 208, .4);
$color-sub-brand-cfd-disabled: rgba(35, 58, 115, .4);

$feeds-green: #66b849;
$feeds-red: #d93e3e;
$red: #D93E3E;
$green: #66B849;
$green-accessible:#3F8426;
$grey-dark: #202020;
$color-down: $red; 
$color-up: $green-accessible; 

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800; 
$white: #ffffff;
$line-height-small: 1.3;
$letter-spacing: .5px;
$card-gradient: radial-gradient(44% 158% at 37% 90%, $gr-blue 0%, $dark-blue 100%);
$border-radius-medium: 1.125rem;
$border-radius-large: 1.5rem;

$chevron-left: url("data:image/svg+xml, #{generate-svg(16, 16, $white, "M6.4665 12.4668L10.4665 8.46683C10.7332 8.20016 10.7332 7.80016 10.4665 7.5335L6.4665 3.5335C6.19984 3.26683 5.79984 3.26683 5.53317 3.5335C5.2665 3.80016 5.2665 4.20016 5.53317 4.46683L9.0665 8.00016L5.53317 11.5335C5.39984 11.6668 5.33317 11.8668 5.33317 12.0002C5.33317 12.1335 5.39984 12.3335 5.53317 12.4668C5.79984 12.7335 6.19984 12.7335 6.4665 12.4668Z")}") !default;
$chevron-right: url("data:image/svg+xml, #{generate-svg(16, 16, $white, "M9.53349 3.53317L5.53349 7.53317C5.26683 7.79984 5.26683 8.19984 5.53349 8.4665L9.5335 12.4665C9.80016 12.7332 10.2002 12.7332 10.4668 12.4665C10.7335 12.1998 10.7335 11.7998 10.4668 11.5332L6.93349 7.99984L10.4668 4.4665C10.6002 4.33317 10.6668 4.13317 10.6668 3.99984C10.6668 3.8665 10.6002 3.6665 10.4668 3.53317C10.2002 3.2665 9.80016 3.2665 9.53349 3.53317V3.53317Z")}") !default;
$chevron-left-large: url("data:image/svg+xml, #{generate-svg(8, 14, $white, "M7,14.1c-0.3,0-0.5-0.1-0.7-0.3l-6-6.1c-0.4-0.4-0.4-1,0-1.4l6-6.1c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4 L2.4,7.1l5.3,5.4c0.4,0.4,0.4,1,0,1.4C7.5,14,7.3,14.1,7,14.1z")}") !default;
$chevron-right-large: url("data:image/svg+xml, #{generate-svg(8, 14, $white, "M1,14.1c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.4L0.3,1.7c-0.4-0.4-0.4-1,0-1.4 c0.4-0.4,1-0.4,1.4,0l6,6.1c0.4,0.4,0.4,1,0,1.4l-6,6.1C1.5,14,1.3,14.1,1,14.1z")}") !default;

$card-skew: skew(0deg, rtlFlip(4deg, -4deg));

$card-gradient: radial-gradient(44% 158% at 37% 90%, $gr-blue 0%, $dark-blue 100%);
$menu-shadow: 0 .25rem .25rem 0 scale-color($black, $alpha:-75%);
$sdd-shadow: 0 4.5rem 3.75rem 0 scale-color($black, $alpha:-80%);
$box-shadow: 0 1.8rem 2rem 0 scale-color($black, $alpha:-75%);
$img-shadow: 0 1.125rem 2rem 0 scale-color($black, $alpha:-70%);
$content-shadow: 0 .25rem .5rem 0 scale-color($black, $alpha:-80%);
$table-shadow: 0 1.5rem 2.5rem 0 scale-color($black, $alpha:-90%);
$card-white-shadow: 0 1.375rem 4.9rem 0 scale-color($black, $alpha:-75%);
$card-shadow: 0 2.75rem 3.625rem scale-color($cfd-blue, $alpha:-70%);
$card-small-shadow: 0 1rem 1.5rem 0 scale-color($black, $alpha:-90%);
$field-shadow: 0 2rem 3rem scale-color($cfd-blue, $alpha:-70%);

// from _header
$color-text: #5e5e5e;
$color-light: #82b5d9;
$light-gray: #bbbbbb;
$light-green: #69f2ec;
$rw-minus-icon: url("data:image/svg+xml, #{generate-svg(32, 32, $white, "M2 14h28v4h-28v-4z")}") !default;
$rw-plus-icon: url("data:image/svg+xml, #{generate-svg(32, 32, $white, "M30 14h-12v-12h-4v12h-12v4h12v12h4v-12h12z")}") !default;
$menu-chevron-down: url("data:image/svg+xml, #{generate-svg(10, 7, $dark-blue, "M0.533169 1.96675L4.53317 5.96675C4.79984 6.23341 5.19984 6.23341 5.4665 5.96675L9.4665 1.96675C9.73317 1.70008 9.73317 1.30008 9.4665 1.03341C9.19984 0.766748 8.79984 0.766748 8.53317 1.03341L4.99984 4.56675L1.4665 1.03341C1.33317 0.90008 1.13317 0.833414 0.999836 0.833414C0.866503 0.833414 0.666502 0.90008 0.533169 1.03341C0.266503 1.30008 0.266503 1.70008 0.533169 1.96675Z")}") !default;
$chevron-down-light-blue: url("data:image/svg+xml, #{generate-svg(14, 8, $medium-blue, "M13.461 0.432017C13.075 0.0460171 12.497 0.0460171 12.111 0.432017L7.00001 5.54302L1.88901 0.432017C1.50301 0.0460171 0.925012 0.0460171 0.539012 0.432017C0.153012 0.818017 0.153012 1.39602 0.539012 1.78202L6.32501 7.56802C6.51801 7.76102 6.80701 7.85702 7.00001 7.85702C7.19301 7.85702 7.48201 7.76102 7.67501 7.56802L13.461 1.78202C13.847 1.39602 13.847 0.818017 13.461 0.432017Z")}") !default;
$chevron-up-light-blue: url("data:image/svg+xml, #{generate-svg(14, 8, $medium-blue, "M13.7 6.3L7.7 0.3C7.3 -0.1 6.7 -0.1 6.3 0.3L0.3 6.3C-0.1 6.7 -0.1 7.3 0.3 7.7C0.7 8.1 1.3 8.1 1.7 7.7L7 2.4L12.3 7.7C12.5 7.9 12.8 8 13 8C13.2 8 13.5 7.9 13.7 7.7C14.1 7.3 14.1 6.7 13.7 6.3Z")}") !default;
$menu-chevron-down-white: url("data:image/svg+xml, #{generate-svg(10, 7, $white, "M0.533169 1.96675L4.53317 5.96675C4.79984 6.23341 5.19984 6.23341 5.4665 5.96675L9.4665 1.96675C9.73317 1.70008 9.73317 1.30008 9.4665 1.03341C9.19984 0.766748 8.79984 0.766748 8.53317 1.03341L4.99984 4.56675L1.4665 1.03341C1.33317 0.90008 1.13317 0.833414 0.999836 0.833414C0.866503 0.833414 0.666502 0.90008 0.533169 1.03341C0.266503 1.30008 0.266503 1.70008 0.533169 1.96675Z")}") !default;
$menu-chevron-down-hover: url("data:image/svg+xml, #{generate-svg(10, 7, $light-green, "M0.533169 1.96675L4.53317 5.96675C4.79984 6.23341 5.19984 6.23341 5.4665 5.96675L9.4665 1.96675C9.73317 1.70008 9.73317 1.30008 9.4665 1.03341C9.19984 0.766748 8.79984 0.766748 8.53317 1.03341L4.99984 4.56675L1.4665 1.03341C1.33317 0.90008 1.13317 0.833414 0.999836 0.833414C0.866503 0.833414 0.666502 0.90008 0.533169 1.03341C0.266503 1.30008 0.266503 1.70008 0.533169 1.96675Z")}") !default;
$search-toggle-icon-white: url("data:image/svg+xml, #{generate-svg(18, 18, $white, "M12.4995 11.5H11.7095L11.4295 11.23C12.4434 10.054 13.0006 8.5527 12.9995 7C12.9995 5.71442 12.6183 4.45772 11.9041 3.3888C11.1898 2.31988 10.1747 1.48676 8.98696 0.994786C7.79924 0.502816 6.4923 0.374095 5.23143 0.624899C3.97055 0.875703 2.81236 1.49477 1.90332 2.40381C0.994279 3.31285 0.375214 4.47104 0.12441 5.73192C-0.126394 6.99279 0.00232807 8.29973 0.494298 9.48744C0.986267 10.6752 1.81939 11.6903 2.88831 12.4046C3.95723 13.1188 5.21394 13.5 6.49951 13.5C8.10951 13.5 9.58951 12.91 10.7295 11.93L10.9995 12.21V13L15.9995 17.99L17.4895 16.5L12.4995 11.5ZM6.49951 11.5C4.00951 11.5 1.99951 9.49 1.99951 7C1.99951 4.51 4.00951 2.5 6.49951 2.5C8.98951 2.5 10.9995 4.51 10.9995 7C10.9995 9.49 8.98951 11.5 6.49951 11.5Z")}") !default;
$search-toggle-icon: url("data:image/svg+xml, #{generate-svg(18, 18, $dark-blue, "M12.4995 11.5H11.7095L11.4295 11.23C12.4434 10.054 13.0006 8.5527 12.9995 7C12.9995 5.71442 12.6183 4.45772 11.9041 3.3888C11.1898 2.31988 10.1747 1.48676 8.98696 0.994786C7.79924 0.502816 6.4923 0.374095 5.23143 0.624899C3.97055 0.875703 2.81236 1.49477 1.90332 2.40381C0.994279 3.31285 0.375214 4.47104 0.12441 5.73192C-0.126394 6.99279 0.00232807 8.29973 0.494298 9.48744C0.986267 10.6752 1.81939 11.6903 2.88831 12.4046C3.95723 13.1188 5.21394 13.5 6.49951 13.5C8.10951 13.5 9.58951 12.91 10.7295 11.93L10.9995 12.21V13L15.9995 17.99L17.4895 16.5L12.4995 11.5ZM6.49951 11.5C4.00951 11.5 1.99951 9.49 1.99951 7C1.99951 4.51 4.00951 2.5 6.49951 2.5C8.98951 2.5 10.9995 4.51 10.9995 7C10.9995 9.49 8.98951 11.5 6.49951 11.5Z")}") !default;
$search-toggle-icon-open: url("data:image/svg+xml, #{generate-svg(14, 14, $light-green, "M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z")}") !default;
$search-input-icon: url("data:image/svg+xml, #{generate-svg(18, 18, #bbbbbb, "M12.4995 11.5H11.7095L11.4295 11.23C12.4434 10.054 13.0006 8.5527 12.9995 7C12.9995 5.71442 12.6183 4.45772 11.9041 3.3888C11.1898 2.31988 10.1747 1.48676 8.98696 0.994786C7.79924 0.502816 6.4923 0.374095 5.23143 0.624899C3.97055 0.875703 2.81236 1.49477 1.90332 2.40381C0.994279 3.31285 0.375214 4.47104 0.12441 5.73192C-0.126394 6.99279 0.00232807 8.29973 0.494298 9.48744C0.986267 10.6752 1.81939 11.6903 2.88831 12.4046C3.95723 13.1188 5.21394 13.5 6.49951 13.5C8.10951 13.5 9.58951 12.91 10.7295 11.93L10.9995 12.21V13L15.9995 17.99L17.4895 16.5L12.4995 11.5ZM6.49951 11.5C4.00951 11.5 1.99951 9.49 1.99951 7C1.99951 4.51 4.00951 2.5 6.49951 2.5C8.98951 2.5 10.9995 4.51 10.9995 7C10.9995 9.49 8.98951 11.5 6.49951 11.5Z")}") !default;
// Risk Warning For Mobile

// RW THEMES //
$rw-background-color: #f6f6f6;
$rw-text-color: $color-text;
$rw-background-color-secondary: #1a1a1e;
$rw-text-color-secondary: $white;

$no-webp: ".safari &, .edge &, .ff-old &";
$rtl-languages: ".lang-he &, .lang-ar &";
$long-languages: ".lang-fi &, .lang-et &, .lang-da &, .lang-nl &, .lang-no &, .lang-sv &";

$banner-image-gradient : radial-gradient(circle at center, scale-color($dark-blue, $alpha:-60%) 0, $dark-blue 80%, $dark-blue 100% );
$banner-image-gradient-small-device : linear-gradient(to bottom, scale-color($dark-blue, $alpha:-100%) 15%, $dark-blue 85%);

$banner-blue-gradient : linear-gradient(to #{$align-def}, scale-color($dark-blue, $alpha:-100%) 30%, $dark-blue 80%);
$banner-blue-gradient-small-device : linear-gradient(to bottom, scale-color($dark-blue, $alpha:-100%) 0, $dark-blue 95%);
$image-blue-gradient : linear-gradient(to top, scale-color($white, $alpha:-80%) 15%, $dark-blue 100%);
$section-blue-gradient : linear-gradient(to top, scale-color($dark-blue, $alpha:-70%) 0, $dark-blue 50%);
$section-white-gradient : linear-gradient(to bottom, scale-color($white, $alpha:-100%) 0, $white 25%);

// All instruments
$date-light-blue: #eaf3ff;