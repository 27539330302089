﻿/// <reference path="../../../../node_modules/bootstrap/scss/bootstrap.scss" />
/// <reference path="../_variables.scss" />
@import "components/global-search";
// Risk Warning
%prominant-colors { background-color:$rw-background-color-secondary; color:$rw-text-color-secondary; }
:root  {
	--offcanvas-height:var(--viewh, 100vh);
	--languages-column-size:3;
	--languages-column-width:1fr;
}
.main-header {
	.rw-container { background-color:$rw-background-color; color:$rw-text-color; text-align:center;
		@include media-breakpoint-up(lg){ text-align:left; }
		&.blocked { @extend %prominant-colors; }
		.main-row { padding-top:$spacer * .25; padding-bottom:$spacer *.75;
			@include media-breakpoint-up(lg){ padding-top:$spacer * .5; padding-bottom:$spacer *.5; }
		}
	}
	&.prominent-rw {
		.rw-container { @extend %prominant-colors; 
			.regulator-cysec.lang-cs & { 
				@include media-breakpoint-up(lg){ background-color:$rw-background-color;  color:$black; }
			}
		}
	}
	
	.rw-text { grid-template-rows:1fr auto; grid-template-columns:1fr; position:relative; z-index:1; 
		p { grid-column:1; grid-row:1; font-size:.75rem; margin:0; letter-spacing:0; line-height:1;// display grid with both paragraphs ocupping the same cell prevents jumps when transitioning
			@include media-breakpoint-up(md){ font-size:.9375rem; line-height:initial; letter-spacing:initial; }
			.regulator-cysec & { font-size:.875rem;
				@include media-breakpoint-up(md){ font-size:1.125rem; }
				@at-root .lang-cs#{&} { @include media-breakpoint-up(lg){ font-size:1.375rem; font-weight:300; } }
			}
			.regulator-scb & { font-weight:$font-weight-bold; }
		}
	}

	.rw-short { opacity:1; transition:opacity .35s ease; 
		@include media-breakpoint-up(lg) { display:none; }
	}
	.collapse.show, .collapsing {
		~ .rw-short { opacity:0; }
	}
	.collapse.show ~ .rw-toggle:before { content:$icon-minus3; }

	.rw-toggle { @extend %prominant-colors; position:absolute; bottom:-1.5rem; left:0; right:0; width:3rem; padding:0; border-radius:0 0 1.5rem 1.5rem; margin:0 auto; border:none; cursor:pointer; z-index:1; box-shadow:none; 
		@include media-breakpoint-up(lg){ display:none; }
		&:focus { outline:0; @include rm-click-hint; } 
	}

	.service-unavailable-banner { background-color: $rw-background-color-secondary; padding: .5rem 0; text-align: center;
		box-shadow: 0 1px 0 0 $rw-background-color-secondary; // get rid of that overtone between elements
		@include media-breakpoint-up(lg){ text-align: #{$align-def}; }
		p { color: $rw-text-color-secondary; font-size: .75rem; font-weight: 400; margin-bottom: 0;
			@include media-breakpoint-up(lg) { font-size: .9375rem; }
			span { display: inline-block; }
			a { color: $rw-text-color-secondary; text-decoration: underline; }
		}
	}
}
header {
	&.sticky-top { z-index:$zindex-sticky + 1; } // this needs to be set via bootstrap's variables
	nav { background-color:$body-bg; transition:all .3s ease-in-out;
		.is-sticky & { background-color:scale-color($white, $alpha:-15%); border-radius: 0 0 .75rem .75rem; }
		&.navbar { padding-top:$spacer * .75; } 
		.logo {
			svg { max-width:8rem; margin-bottom:.25rem;
				@include media-breakpoint-up(xl){ max-width:10rem; margin-bottom:.5rem; }
			}
			&-color-1 { fill:$color-sub-brand-cfd;
				.blue-header & { fill:$white; }
			}
			&-color-2 { fill:$color-sub-brand-cfd; stroke: none;
				.blue-header & { fill:$white; }
			}
			&-desc { color:$dark-blue; font-size:.8125rem; font-weight:$font-weight-medium; }
		}
		// Item Order
		.logo					{ order:1; }
		.switcher				{ order:1; }
		.header-trade			{ order:2; }
		.dropdown-toggle-search	{ order:2; @include media-breakpoint-up(lg){ order:5; } }
		.navbar-toggler			{ order:3; }
		.main-header-offcanvas	{ order:4; }
		.start-trading-link-box	{ order:6; }
		/* empty options forces defaults. */
	    /*! rtl:options:{} */
		.islamic-account & { background-image: url(../Images/islamic-account-topbar-bg.png); background-repeat: no-repeat; background-position: center -5px;
			@include media-breakpoint-up(lg){ background-position: center top; background-size: cover; }
		}
	}

	.#{&}-trade { margin-left:$spacer * .5; font-size: clamp(.75rem,4vw,1rem);
		@at-root [class^="invest-"] #{&} { display:none; }
	}

	.dropdown {
		* { @include media-breakpoint-up(lg){ transition:none !important; } }
		&-header { --bs-dropdown-header-color:#{scale-color($dark-blue, $saturation:-55%, $lightness:64%)}; font-weight:$font-weight-medium; 
			@include media-breakpoint-down(lg){ --bs-dropdown-header-padding-x:0; --bs-dropdown-header-padding-y:#{$spacer * 1.125}; border-bottom:1px solid scale-color($white, $alpha:-75%); }
		}
		// fix scrolling issues on mobile devices (false focus events)
		&-toggle, &-item, &-toggle.nav-link { box-shadow:none;
			@media (pointer:coarse) and (max-width:map-get($grid-breakpoints, lg)) {
				border-bottom:1px solid scale-color($white, $alpha:-75%); transition:none;
				&:hover { --bs-dropdown-link-hover-bg:none; --bs-nav-link-hover-color:#{$light-blue}; --bs-dropdown-link-hover-color:#{$light-blue}; border-bottom-color:$light-blue; }
				&:active { --bs-dropdown-link-active-bg:none; }
				&:focus { color:$light-blue; border-bottom-color:$light-blue; }
			}
			&.no-border-bottom { @include media-breakpoint-down(lg){ border-bottom:none; } }
		}
		&-toggle {
			@include media-breakpoint-up(lg){ border-radius:.5rem .5rem 0 0; }
			&:not(.btn) { // do not apply on cfd/invest product switcher
				@include media-breakpoint-down(lg){ @include caret($direction:end); position:relative; padding-right:$spacer * 2.5; 
					&:after { position:absolute; top:0; bottom:0; right:1.75rem; margin:auto 0; height:.25rem; }
				}
			}
			&-languages:after { @include media-breakpoint-down(lg){ right:auto; left:2rem; } }
			&.show { --bs-nav-link-color:#{$white}; --bs-navbar-active-color:#{$white}; background-color:$dark-blue; }
		}
		&-item { --bs-dropdown-item-padding-x:0; --bs-dropdown-item-padding-y:#{$spacer * 1.125}; white-space:normal;
			@include media-breakpoint-up(lg){ --bs-dropdown-item-padding-x:#{$spacer}; --bs-dropdown-item-padding-y:#{$spacer * .5}; }
		}
		&-divider { --bs-dropdown-divider-margin-y:0; }
		&-menu, &-menu[data-bs-popper] { margin-top:-($spacer * .125); border:none; 
			@include media-breakpoint-up(lg){ border-radius:0 .5rem .5rem .5rem;
				&.dropdown-menu-end { border-radius:.5rem 0 .5rem .5rem; }
			}
		}
	}

	// Hack for dropdown hover for desktops above 1024px
	@media (hover: hover) and (pointer: fine) and (min-width:map-get($grid-breakpoints, lg)) {
		.nav-item.dropdown {
			&:hover  {
				> .dropdown {
					&-menu { display:block;
						// following line are for old browser's support since I can't use :not selector
						&-languages, &-search { display:none; }
						&-languages.show { display:block; }
						&-many-languages.show { display:grid; }
						&-search.show { display:flex; }
					}
					&-toggle { --bs-nav-link-color:#{$white}; --bs-nav-link-hover-color:#{$white}; background-color:$dark-blue;
						&-languages { border-radius:.5rem;
							&.show { border-radius:.5rem .5rem 0 0; }
						}
						&:focus { --bs-nav-link-hover-color:#{$white}; }
					}
				} 
			} 
			> .dropdown-toggle {
				&:active { --bs-nav-link-hover-color:#{$white}; --bs-nav-link-color:#{$white}; background-color:$dark-blue; pointer-events:none; } // Without this, clicking will make it sticky
				&:focus { --bs-nav-link-hover-color:#{$dark-blue}; }
				&-languages:active { pointer-events:auto; } // return pointer events to languages toggle and search toggle
			}
		}
	}

	// Animated hamburger menu icon Based on https://codepen.io/designcouch/pen/ExvwPY
	.navbar-toggler { position:relative; border:none; border-radius:0; box-shadow:none; padding:0; width:1.25rem; height:1.25rem; margin:$spacer * .375; overflow:hidden; 
		span { position:absolute; left:0; background-color:$dark-blue; display:block; height:2px; width:100%; transform:rotate(0deg); transition:.25s ease-in-out; opacity:1; 
			&:nth-child(1) { top:3px; }
			&:nth-child(2) { top:9px; }
			&:nth-child(3) { top:15px; }
		}	
		&.show {
			span {
				&:nth-child(1) { top:9px; transform:rotate(135deg); }
				&:nth-child(2) { left:18px; opacity:0; }
				&:nth-child(3) { top:9px; transform:rotate(-135deg); }
			}
		}
	}
	.offcanvas {
		&.offcanvas {
			&-start, &-end { 
				@include media-breakpoint-down(lg){ height:var(--offcanvas-height); }
				@include media-breakpoint-down(md){ --bs-offcanvas-width:100%; } 
			}
		}
		&-body { @include media-breakpoint-down(lg){ --bs-offcanvas-padding-x:0; --bs-offcanvas-padding-y:0; display:flex; flex-direction:column; height:var(--offcanvas-height); } }
		.navbar-toggler span { background-color:$white; }
		.navbar-nav { --bs-nav-link-color:#{$white}; --bs-nav-link-hover-color:#{$light-blue}; --bs-navbar-active-color:#{$light-blue}; --bs-nav-link-padding-x:0; --bs-nav-link-padding-y:.75rem; flex-grow:1;
			position:relative; display:flex; justify-content:flex-start;
			@include media-breakpoint-down(lg){ order:2; }
			@include media-breakpoint-up(lg){ --bs-nav-link-padding-y:#{$spacer * .5}; --bs-nav-link-padding-x:#{$spacer}; --bs-nav-link-color:#{$dark-blue}; }
			.nav-link { @include media-breakpoint-up(lg){ --bs-navbar-nav-link-padding-x:#{$spacer * .5}; --bs-navbar-nav-link-padding-y:#{$spacer * .75}; white-space:normal; display:flex; align-items:center; text-align:center; } }
			li { margin-bottom:0; // reset margin bottom for list items
				&:last-child .dropdown-item { border-bottom:0; }
			}
		}
		&.main-header-offcanvas { @include media-breakpoint-up(lg){ margin:0 ($spacer * .5); }
			.back { 
				@include media-breakpoint-down(lg){ display:flex; justify-content:space-between; align-items:center; line-height:1;
					a, button { color:$white; padding:($spacer * 1.5) $spacer; }
					button { background:transparent; border:0; }
				}
			}
			.btn {
				&-back { font-size:1rem; text-transform:unset; position:relative; flex:1 1 100%;
					&:before { content:$icon-chevron-left #{"/*!rtl:"$icon-chevron-right"*/"}; position:absolute; left:-1rem; top:0; bottom:0; width:1rem; height:1rem; margin:auto 0; }
				}
				&-dismiss { display:flex; justify-content:right;
					&:only-child { display:inline-block; margin-left:auto; } // better handling for even older browsers
				}
			}
		}
		.nav {
			&-item { @include media-breakpoint-down(lg){ padding-left:$spacer * 2; } }
			&-link { @include media-breakpoint-down(lg){ --bs-nav-link-font-size:1.25rem; --bs-nav-link-padding-y:#{$spacer * 1.125}; } }
		}
		.dropdown { @include media-breakpoint-down(lg){ position:static; }
			&-menu { --bs-body-bg:#{$dark-blue}; --bs-dropdown-padding-y:0; 
				@include media-breakpoint-down(lg){ --bs-dropdown-spacer:0; --bs-dropdown-border-radius:0; 
					display:flex; flex-direction:column; position:absolute; top:0; bottom:0; width:100%;
					transition:transform .3s ease-in-out; transform-origin:top center; transform:translateX(-120%);
				}
				@include media-breakpoint-up(lg){ --bs-dropdown-padding-y:.5rem; flex-direction:row; justify-content:center; min-width:16rem; }
				&-dark { --bs-dropdown-header-color:#{$light-blue}; --bs-dropdown-divider-bg:#{$light-blue}; @include media-breakpoint-down(lg){ --bs-dropdown-box-shadow:0; } }
				&.show { @include media-breakpoint-down(lg){ transform:translateX(0%); } }
				.nav-title, .nav-item.back { @include media-breakpoint-up(lg){ display:none; }}
				.nav-title { color: var(--bs-nav-link-hover-color); }
				.nav-link { --bs-nav-link-color:#{$white}; --bs-navbar-nav-link-padding-x:#{$spacer * 2.5}; padding-left:var(--bs-navbar-nav-link-padding-x);
					display:flex; justify-content:space-between; align-items:center; outline:none;
					@include media-breakpoint-up(lg){ --bs-navbar-nav-link-padding-x:#{$spacer}; --bs-nav-link-padding-y:#{$spacer * .75}; text-align:center; display:block; }
					.IPhone &:focus-visible { box-shadow:none; }
				}
				.nav-item-doc:not(:has(~ .nav-item-doc)) .dropdown-item {
					@include media-breakpoint-down(lg){ border-bottom:none; }
				}
				&-languages { padding-left:$spacer * 2;
					@include media-breakpoint-up(lg){ padding:$spacer * .5; }
					&.show { display:block; }
					.back { padding-left:0; }
				}
				&-many-languages { --bs-dropdown-font-size:.875rem; grid-template-columns:repeat(var(--languages-column-size), var(--languages-column-width));
					grid-auto-rows:max-content; align-items:center; grid-gap:$spacer * .5; gap:$spacer * .5;
					@include media-breakpoint-down(lg){ display:grid; }
					@include media-breakpoint-up(md){ --languages-column-size:4; }
					@include media-breakpoint-up(lg){ --bs-dropdown-font-size:1rem; --languages-column-width:6.875rem; } // fixed width, removed the "jump" while grid renders the languages menu
					&.show { display:grid; }
					.back { grid-column:span var(--languages-column-size); }
					.dropdown-item { white-space:normal; --bs-dropdown-item-padding-x:#{$spacer * .25}; --bs-dropdown-item-padding-y:#{$spacer * .5}; border-bottom:0;
						@include media-breakpoint-up(lg){ --bs-dropdown-item-padding-x:#{$spacer}; --bs-dropdown-item-padding-y:#{$spacer * .25}; }
					}
				}
			}
		}
	}

	a.nav-link, a.link-trade { 
		&:first-letter { text-transform:uppercase; }
		&.dropdown-toggle-languages{ text-transform:uppercase; }
		.lang-en &.main-header { text-transform: uppercase; }
	}
}

.start-trading-link-box { flex:0 1 auto;
	@include media-breakpoint-down(lg){ display:none; } // important to override
	.rw-btn-invest { font-size:.875rem; display:block; text-align:center; padding-top:.25em; }
}