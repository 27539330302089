﻿//// MIXINS FOR LONG LANGUAGES ////
@mixin title-size-lang-1 {
	&.home .main-banner-title, &.home .main-banner-slogan { 		
		@include media-breakpoint-up(md){ font-size:2rem; }
		@include media-breakpoint-up(lg){ font-size:4rem; }
	}
}
@mixin title-size-lang-2 {
	&.home .main-banner-title, &.home .main-banner-slogan { 		
		@include media-breakpoint-up(md){ font-size:2.5rem; }
		@include media-breakpoint-up(lg){ font-size:4rem; }
	}
}
@mixin menu-start-trading-button {
	.btn.header-trade {
		@include media-breakpoint-up(sm){ --bs-btn-padding-x:.5rem; }
	}
}
@mixin main-banner-bg-background-position { 
	@include media-breakpoint-between(md,lg){
		.main-banner.hero-banner:before {
			#{"/*! rtl:ignore */"}background-position:right -56vw top -3rem;
		}
	}
}
@mixin proaccount-campaign-titles {
    @include media-breakpoint-only(sm){
        .main-section.pro-campaign {
			h1 { font-size: 1.75rem; }
            h2 { font-size: 1.25rem; }
        }
    }
}
@mixin cookie-banner-long-langs {
	.cookie-banner-wrapper {
		a { font-size: 0.8125rem; }
	}
}
////  LANGUAGES  ////
.lang-ar, .lang-he {
	@include main-banner-bg-background-position;
}
.lang-en {
	.title-section { strong, span { text-transform:uppercase; } }
}
.lang-bg {
	@include title-size-lang-1;
	@include proaccount-campaign-titles;
	@include cookie-banner-long-langs;
} 
.lang-cn {
	.footer-text {
        * { line-height:1.4; }
    }
}
.lang-el {
	@include title-size-lang-2;	
	@include proaccount-campaign-titles;
}
.lang-et {
	@include proaccount-campaign-titles;
}
.lang-fi {
	@include title-size-lang-2;	
	@include menu-start-trading-button;
} 
.lang-fr { 
	@include title-size-lang-2;		
} 
.lang-lt {
	@include proaccount-campaign-titles;
}
.lang-nl {
	@include title-size-lang-1;	
} 
.lang-pt {
	@include title-size-lang-2;		
} 
.lang-sk {
	@include title-size-lang-1;	
	@include cookie-banner-long-langs;
}
.lang-ru {
	@include title-size-lang-2;	
	@include cookie-banner-long-langs;
}
////  COUNTRIES  ////
.country-cn {
	.instrument-button {
        .button {
            &-buy:hover { background:$color-down; }
            &-sell:hover { background:$color-up-bg; }
        }
    }
	.inst {
        &-up { color:$color-down;
            span {color:$color-down; }
        }
        &-down { color:$color-up;
            span {color:$color-up; }
        }
    }
	.client-sentiment {
	    .meter { background:$color-up-bg;
		    span { background:$color-down; }
	    }
        .percentage {
            strong { color:$color-down;
                span { color:$color-down;}
                &:last-child { color:$color-up;
                    span { color:$color-up;}
                }
            }
        }
    }
	.tools-img~ul li:before { color:$color-down; }
}