﻿@import "../vendor/slick-slider";
// seo slider styles
.seo-carousel-section { overflow: hidden; position: relative; }
.seo-slider { overflow: hidden;
    @include media-breakpoint-up(lg) { padding: 2rem 0; }
    &-wrapper { position: relative;
        &:before, &:after { 
            @include media-breakpoint-up(lg) { 
                content: ''; position: absolute; top: 0; pointer-events: none; height: 100%; width: 30%; z-index: 1; 
            } 
        }
        &:before { 
            @include media-breakpoint-up(lg) { #{$align-opp}: 0; background-image: linear-gradient(to #{$align-def}, $white 0%, rgba(255,255,255,0) 25%); }
        }
        &:after {
            @include media-breakpoint-up(lg) { #{$align-def}: 0; background-image: linear-gradient(to #{$align-opp}, $white 0%, rgba(255,255,255,0) 25%); }
        }
    }
    // prevent flash of unstyled content
    > div {
        @include media-breakpoint-up(lg) { padding: 7rem 5%; margin: 0 auto; }
        @include media-breakpoint-up(xxl) { padding: 7rem 20%; }
        //@include media-breakpoint-up(xxxlg) { padding: 7rem 25%; } // TODO: xxxxlg does not exist in bootstrap 
        + div { display: none; } // hide next slides to prevent layout shift when slider initializes
    }
    &.slick-initialized {
        .slick-list { 
            @include media-breakpoint-up(lg) {  margin: 0; }
        }
        .slick-slide { display: flex; } // set same height for all cases
    }
    // unset restrictions
    .slick {
        &-track { display: flex; }
        &-slide { cursor: grab; height: auto;
            &:active { cursor: grabbing; }
            @include media-breakpoint-up(lg) { padding: 7rem 3rem; }
        }        
        &-dotted { padding-bottom: 2.875rem; }
        &-dots { position: absolute; bottom: 0; display: block; width: 100%; height: 2.25rem; padding: 0; margin: 0; list-style: none; text-align: center;
            li { position: relative; display: inline-block; width: 1rem; height: 1rem; margin: 0 .375rem; padding: 0; cursor: pointer;
                button { background-color: #9e9e9e; border-radius: 50%; cursor: pointer; display: block; font-size: 0; height: .75rem; width: .75rem; overflow: hidden; position: relative;
                    @include media-breakpoint-up(lg) { height: 1rem; width: 1rem; }
                    &:hover, &:focus { outline: none;
                        &:before { opacity: 1; }
                    }
                    &:before { content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: .25; }
                }
                &.slick-active button:before { opacity: .75; background-color: $light-blue; }
            }
        }
    }
}

.slide {
    &-wrapper { width: 100%;
        @include media-breakpoint-up(lg) { border-radius: 1.75rem; box-shadow: 0 2.125rem 4.25rem 0 rgba(0, 0, 0, .13); overflow: hidden; position: relative; }
        &:before {
            @include media-breakpoint-up(lg) {
                content: ''; background: url(../Images/newhome/svg/border-gradient-top-#{$align-opp}.svg) no-repeat 50%; background-size: 100% 100%; position: absolute; top: 1rem; right: 1rem; bottom: 1rem; left: 1rem; z-index: 1;
            }
        }
    }
    &-inner-wrapper { display: flex; flex-wrap: wrap; height: 100%; position: relative;
        @include media-breakpoint-up(lg) { border-radius: 1.125rem; overflow: hidden; }
    }
    &-content { display: flex; flex-direction: column; flex: 1 0 100%; height: auto; order: 2; padding: 2rem 1rem; position: relative; z-index: 1; text-align: center;
        @include media-breakpoint-up(lg) { flex: 0 1 60%; max-width: 60%; order: 1; padding: 3.5rem 3.25rem; text-align: #{$align-def}; }
        .title { color: $dark-blue; font-size: 1.375rem; font-weight: $font-weight-light; line-height: 1.1;
            @include media-breakpoint-up(lg) { font-size: 2rem;
                .lang-he & { font-size: 2rem; }
            }
            strong { display: block; font-size: 1.875rem; font-weight: $font-weight-extra-bold; color: $medium-blue;
                .lang-ar & { font-weight: $font-weight-bold; }
                .lang-he & { font-weight: $font-weight-medium; }
                .lang-en & { text-transform: uppercase; }
                @include media-breakpoint-up(lg) { font-size: 3rem; }
            }
        }
        p { font-size: .875rem; font-weight: $font-weight-regular;
            @include media-breakpoint-up(lg) { font-size: 1.125rem; }
        }
        .cta-wrapper { margin-top: auto; margin-bottom: 2rem;
            &:last-child {
                @include media-breakpoint-up(lg) { margin-bottom: 0; } // in case it's the last element
            } 
        }
        .risk-warning-notice { font-size: .75rem;
            @include media-breakpoint-up(lg) { font-size: .875rem; }
        }
    }
    &-image-placeholder { flex: 1 0 100%; order: 1; height: 15rem; padding: 0 1rem;
        @include media-breakpoint-up(md) { height: 20rem; }
        @include media-breakpoint-up(lg) { flex: 0 1 40%; max-width: 40%; order: 2; height: auto; padding: 0; }
        .image-background { background-position: 50%; background-repeat: no-repeat; background-size: cover; height: 100%; opacity:0; transition:opacity .75s ease-in-out;
            @include media-breakpoint-up(lg) { background-size: cover; border-radius: rtlFlip(0 1.125rem 1.125rem 0, 1.125rem 0 0 1.125rem); width: 40%; position: absolute; #{$align-opp}: 0; }
            &.lazyloaded { opacity:1; }
        }
    }
}

