﻿ .regulatory { margin-bottom: $spacer * 5; 
    @include media-breakpoint-up(md) { margin-bottom: $spacer * 9; }
    &.experiment-reorder { margin-top: $spacer * 5; 
        @include media-breakpoint-only(sm) { margin-bottom: 0; }
    }
   .blue-card-content {
      @include media-breakpoint-up(md) { padding-left: $spacer * 2.5; padding-right: 0; padding-top: $spacer * 2; padding-bottom: $spacer * 2; }
      @include media-breakpoint-up(lg) { padding-left: $spacer * 3.5; padding-top: $spacer * 5; padding-bottom: $spacer * 3; }  
      @include media-breakpoint-up(xxl) { padding-left: $spacer * 4.5; }
   }
   .title-section { margin-bottom: $spacer; }
    &-list-container { display: flex; align-items: center; flex-direction: column; }
    &-buttons { width: 100%; }
    &-list-data { list-style: none; color: $dark-blue; font-size: 1.25rem; font-weight: $font-weight-light; line-height: $line-height-small; margin:0 ($spacer * .5) ($spacer * 2) ($spacer * .5);
     @include media-breakpoint-up(md){ font-size: 1.5rem; }   
     @include media-breakpoint-up(lg){ 
         @at-root .regulator-cysec.lang-cs & { font-size:1.375rem; }  
     } 
        li { position: relative; padding: $spacer; padding-left: $spacer * 2.5; backface-visibility: hidden; 
            &:before { content: ''; position: absolute; top:50%; left:0; width:1.375rem; height: 1.375rem; margin-top:$spacer * -.6875;
            background-image: url("../Images/newhome/svg/plus-blue-fill.svg"); background-repeat: no-repeat; background-size: 100% auto; 
               @include media-breakpoint-up(md) { width:2rem; height: 2.2rem; margin-top: $spacer * -1.1; }
            }
            @include media-breakpoint-up(md) { padding: $spacer * 1.5; padding-left: $spacer * 4; }
            a { text-decoration: underline; 
              &:hover, &:focus { text-decoration: none; }
            }
        }
     }
    &-content-data { padding: $spacer; } 
   .plus-bg {  
      @include media-breakpoint-only(sm) { display: none; } 
      &-small { width: 11.25rem; height: 11.25rem; top:100%; margin-top: 6%; right:-5%; }
      &-medium { width: 22.75rem; height: 22.75rem; top:55%; left:-10%; }
    }
}