$icomoon-font-family: "icomain" !default;
$icomoon-font-path: "../fonts" !default;

$icon-chat-bubble: unquote('"\\e96e"');
$icon-mail-solid: unquote('"\\e974"');
$icon-whatsapp-solid: unquote('"\\e975"');
$icon-social-viber: unquote('"\\e976"');
$icon-social-line-full: unquote('"\\e977"');
$icon-social-imessage: unquote('"\\e978"');
$icon-arrow-horizontal: unquote('"\\e96f"');
$icon-watchlist: unquote('"\\e970"');
$icon-password: unquote('"\\e971"');
$icon-power: unquote('"\\e972"');
$icon-wifi: unquote('"\\e973"');
$icon-bubble-person: unquote('"\\e96c"');
$icon-telegram: unquote('"\\e96a"');
$icon-soicial-telegram: unquote('"\\e96a"');
$icon-flame: unquote('"\\e969"');
$icon-risers-fallers: unquote('"\\e968"');
$icon-equalizer: unquote('"\\e959"');
$icon-twitter: unquote('"\\e967"');
$icon-social-twitter: unquote('"\\e967"');
$icon-risk-management: unquote('"\\e952"');
$icon-initiation: unquote('"\\e964"');
$icon-trading-methods: unquote('"\\e965"');
$icon-limit-risk: unquote('"\\e966"');
$icon-deposit-adjustment: unquote('"\\e951"');
$icon-error-fill: unquote('"\\e94f"');
$icon-error: unquote('"\\e950"');
$icon-plus-fill: unquote('"\\e95d"');
$icon-arrow-next: unquote('"\\e95e"');
$icon-vision: unquote('"\\e960"');
$icon-step: unquote('"\\e961"');
$icon-star-circle: unquote('"\\e962"');
$icon-star: unquote('"\\e963"');
$icon-social-line: unquote('"\\e95a"');
$icon-social-instagram: unquote('"\\e95b"');
$icon-facebook: unquote('"\\e95c"');
$icon-social-facebook: unquote('"\\e95c"');
$icon-search1: unquote('"\\e946"');
$icon-plus: unquote('"\\e947"');
$icon-menu: unquote('"\\e948"');
$icon-mail: unquote('"\\e949"');
$icon-lock: unquote('"\\e94a"');
$icon-info-circle: unquote('"\\e94b"');
$icon-help: unquote('"\\e94c"');
$icon-check-circle: unquote('"\\e94d"');
$icon-faq-circle: unquote('"\\e94e"');
$icon-news: unquote('"\\e953"');
$icon-prepare: unquote('"\\e954"');
$icon-spread: unquote('"\\e955"');
$icon-knowledge: unquote('"\\e956"');
$icon-history: unquote('"\\e957"');
$icon-deposit-withdraw: unquote('"\\e958"');
$icon-deposit: unquote('"\\e945"');
$icon-withdraw: unquote('"\\e944"');
$icon-additional: unquote('"\\e943"');
$icon-bonus: unquote('"\\e942"');
$icon-download-pdf: unquote('"\\e93b"');
$icon-futures-interestrates: unquote('"\\e91a"');
$icon-futures-forex: unquote('"\\e91b"');
$icon-futures-crypto1: unquote('"\\e93c"');
$icon-options: unquote('"\\e913"');
$icon-stocks: unquote('"\\e914"');
$icon-commodities: unquote('"\\e916"');
$icon-forex: unquote('"\\e917"');
$icon-indices: unquote('"\\e918"');
$icon-etfs: unquote('"\\e919"');
$icon-webApp: unquote('"\\e90d"');
$icon-wallet-coin: unquote('"\\e912"');
$icon-cannabis: unquote('"\\e90b"');
$icon-Singapore: unquote('"\\e908"');
$icon-Devices: unquote('"\\e909"');
$icon-zero-percent: unquote('"\\e906"');
$icon-IslamicSquareFull: unquote('"\\e901"');
$icon-IslamicSquareSmall: unquote('"\\e902"');
$icon-IslamicSquareBig: unquote('"\\e903"');
$icon-IslamicMoon: unquote('"\\e904"');
$icon-bell-o: unquote('"\\e905"');
$icon-bitcoin: unquote('"\\e900"');
$icon-futures-interestrates1: unquote('"\\e923"');
$icon-futures-forex1: unquote('"\\e921"');
$icon-futures-metals: unquote('"\\e91c"');
$icon-futures-agriculture: unquote('"\\e91d"');
$icon-futures-crypto: unquote('"\\e91e"');
$icon-futures-energy: unquote('"\\e91f"');
$icon-futures-equityindex: unquote('"\\e920"');
$icon-futures-equityindices: unquote('"\\e922"');
$icon-futures-articles: unquote('"\\e92d"');
$icon-futures-video: unquote('"\\e92c"');
$icon-futures-strategies: unquote('"\\e92f"');
$icon-futures-plus: unquote('"\\e924"');
$icon-futures-benefits: unquote('"\\e92b"');
$icon-icon-IBs: unquote('"\\e92e"');
$icon-options1: unquote('"\\e915"');
$icon-stocks1: unquote('"\\e925"');
$icon-crypto: unquote('"\\e926"');
$icon-commodities1: unquote('"\\e927"');
$icon-forex1: unquote('"\\e928"');
$icon-indices1: unquote('"\\e929"');
$icon-etfs1: unquote('"\\e92a"');
$icon-webApp1: unquote('"\\e93a"');
$icon-lamp7-glow: unquote('"\\e939"');
$icon-wallet-coin-color-path1: unquote('"\\e938"');
$icon-wallet-coin-color-path2: unquote('"\\e93d"');
$icon-wallet-coin-color-path3: unquote('"\\e93e"');
$icon-wallet-coin-color-path4: unquote('"\\e93f"');
$icon-wallet-coin-color-path5: unquote('"\\e940"');
$icon-wallet-coin-color-path6: unquote('"\\e941"');
$icon-wallet-coin1: unquote('"\\e937"');
$icon-cannabis1: unquote('"\\e936"');
$icon-Singapore1: unquote('"\\e935"');
$icon-Devices1: unquote('"\\e933"');
$icon-zero-percent1: unquote('"\\e932"');
$icon-IslamicSquareFull1: unquote('"\\e931"');
$icon-IslamicSquareSmall1: unquote('"\\e930"');
$icon-IslamicSquareBig1: unquote('"\\e90c"');
$icon-IslamicMoon1: unquote('"\\e907"');
$icon-youtube: unquote('"\\e96b"');
$icon-social-youtube: unquote('"\\e96b"');
$icon-check: unquote('"\\e96d"');
$icon-chevron-left: unquote('"\\e90e"');
$icon-chevron-down: unquote('"\\e90f"');
$icon-chevron-up: unquote('"\\e910"');
$icon-chevron-right: unquote('"\\e911"');
$icon-menu1: unquote('"\\e000"');
$icon-arrow-back: unquote('"\\e95f"');
$icon-moon-fill: unquote('"\\e90a"');
$icon-night: unquote('"\\e90a"');
$icon-search: unquote('"\\e986"');
$icon-sun: unquote('"\\e9d4"');
$icon-loop2: unquote('"\\ea2e"');
$icon-tab: unquote('"\\ea45"');
$icon-facebook2: unquote('"\\ea91"');
$icon-instagram: unquote('"\\ea92"');
$icon-whatsapp: unquote('"\\ea93"');
$icon-appleinc: unquote('"\\eabe"');
$icon-android: unquote('"\\eac0"');
$icon-windows8: unquote('"\\eac2"');
$icon-linkedin2: unquote('"\\eaca"');
$icon-social-linkedin: unquote('"\\eaca"');
$icon-chrome: unquote('"\\eadb"');
$icon-firefox: unquote('"\\eadd"');
$icon-edge: unquote('"\\eae0"');
$icon-safari: unquote('"\\eadf"');
$icon-file-pdf: unquote('"\\eae1"');
$icon-droplet: unquote('"\\e934"');
$icon-bullhorn: unquote('"\\e979"');
$icon-book: unquote('"\\e990"');
$icon-library2: unquote('"\\e999"');
$icon-graduation: unquote('"\\e99a"');
$icon-file-check: unquote('"\\e9a9"');
$icon-file-text22: unquote('"\\e9ad"');
$icon-file-presentation: unquote('"\\e9bf"');
$icon-file-stats: unquote('"\\e9c1"');
$icon-certificate: unquote('"\\e9eb"');
$icon-coins: unquote('"\\ea0a"');
$icon-coin-dollar: unquote('"\\ea0b"');
$icon-coin-euro: unquote('"\\ea0c"');
$icon-coin-pound: unquote('"\\ea0d"');
$icon-wallet: unquote('"\\ea10"');
$icon-credit-card: unquote('"\\ea15"');
$icon-phone2: unquote('"\\ea1d"');
$icon-phone3: unquote('"\\ea26"');
$icon-envelop3: unquote('"\\ea32"');
$icon-envelop5: unquote('"\\ea34"');
$icon-location: unquote('"\\ea38"');
$icon-location3: unquote('"\\ea41"');
$icon-clock: unquote('"\\ea4d"');
$icon-clock3: unquote('"\\ea4f"');
$icon-bell2: unquote('"\\ea58"');
$icon-calendar4: unquote('"\\ea62"');
$icon-display4: unquote('"\\ea76"');
$icon-mobile2: unquote('"\\ea78"');
$icon-bubble8: unquote('"\\ead8"');
$icon-bubble-dots3: unquote('"\\ead9"');
$icon-bubble-lines3: unquote('"\\eada"');
$icon-users: unquote('"\\eaf8"');
$icon-collaboration: unquote('"\\eb10"');
$icon-search2: unquote('"\\eb33"');
$icon-search3: unquote('"\\eb36"');
$icon-enlarge7: unquote('"\\eb48"');
$icon-lock2: unquote('"\\eb51"');
$icon-safe: unquote('"\\eb57"');
$icon-cog2: unquote('"\\eb61"');
$icon-cog3: unquote('"\\eb62"');
$icon-stats-dots: unquote('"\\eb87"');
$icon-stats-bars3: unquote('"\\eb8b"');
$icon-chart: unquote('"\\eb8f"');
$icon-stats-growth: unquote('"\\eb90"');
$icon-stats-growth2: unquote('"\\eb92"');
$icon-medal: unquote('"\\eba0"');
$icon-gift: unquote('"\\ebac"');
$icon-gift2: unquote('"\\ebad"');
$icon-lamp7: unquote('"\\ebf9"');
$icon-lamp8: unquote('"\\ebfa"');
$icon-briefcase: unquote('"\\ec02"');
$icon-shield: unquote('"\\ec31"');
$icon-shield-check: unquote('"\\ec32"');
$icon-clipboard2: unquote('"\\ec51"');
$icon-menu7: unquote('"\\ec71"');
$icon-earth2: unquote('"\\ec96"');
$icon-earth22: unquote('"\\ec97"');
$icon-link5: unquote('"\\eca1"');
$icon-star-empty: unquote('"\\ece0"');
$icon-star-full: unquote('"\\ece2"');
$icon-star-empty3: unquote('"\\ece3"');
$icon-star-full2: unquote('"\\ece5"');
$icon-thumbs-up2: unquote('"\\ecf5"');
$icon-pointer: unquote('"\\ed30"');
$icon-touch: unquote('"\\ed35"');
$icon-warning: unquote('"\\ed4f"');
$icon-warning2: unquote('"\\ed50"');
$icon-plus3: unquote('"\\ed5d"');
$icon-minus3: unquote('"\\ed5e"');
$icon-info: unquote('"\\ed63"');
$icon-cross2: unquote('"\\ed6d"');
$icon-cross3: unquote('"\\ed6e"');
$icon-checkmark: unquote('"\\ed6f"');
$icon-checkmark4: unquote('"\\ed72"');
$icon-enter3: unquote('"\\ed7a"');
$icon-play3: unquote('"\\ed7e"');
$icon-arrow-up3: unquote('"\\edb9"');
$icon-arrow-up4: unquote('"\\edba"');
$icon-arrow-right3: unquote('"\\edbd"');
$icon-arrow-right4: unquote('"\\edbe"');
$icon-arrow-down3: unquote('"\\edc1"');
$icon-arrow-down4: unquote('"\\edc2"');
$icon-arrow-left3: unquote('"\\edc5"');
$icon-arrow-left4: unquote('"\\edc6"');
$icon-circle-right2: unquote('"\\ede8"');
$icon-circle-left2: unquote('"\\edea"');
$icon-circle-right3: unquote('"\\edee"');
$icon-circle-left3: unquote('"\\edf2"');
$icon-filter3: unquote('"\\ee8b"');
$icon-filter4: unquote('"\\ee8c"');
$icon-page-break2: unquote('"\\eea5"');