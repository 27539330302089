.why-us {
  &-section { margin-bottom:$spacer * 7;
    @include media-breakpoint-up(md){ margin-bottom:$spacer * 15; }
    .plus-bg {
        &-large { top:96%; #{$align-def}:8rem; width:10.5rem; height:10.5rem;  
            @include media-breakpoint-up(md){ width:15.375rem; height:15.375rem; top:80%; #{$align-def}:-10rem; }
        }
      } 
    .item {
        &-title { color:$white; }
        &-content { @include media-breakpoint-up(lg){flex:0 1 70%;} }
    }
  }
  &-items { padding-left:0; padding-right:0;
    @include media-breakpoint-up(lg){ padding-left:var(--bs-gutter-x,$spacer); padding-right:var(--bs-gutter-x,$spacer); } 
  } 
}